/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, filter, from, map, mergeMap, Observable, of, Subscription, take } from 'rxjs';
import { JsEncryptWrapperService } from 'src/app/services/js-encrypt-wrapper.service';
import { RestServicesService } from 'src/app/services/rest-services.service';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent {
  name = 'My first AGM project';
  lat: any;
  lng: any;
  apiLoaded: Observable<boolean>;
  options: google.maps.MapOptions = {
    disableDefaultUI: false,
    zoom: 10
  };
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  obsFlagsdata: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public httpClient: HttpClient,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    private jsEncriptWrapperService:JsEncryptWrapperService,
    private restService:RestServicesService) {
    if (data) {
      data.lat = 24.212246;
      data.lng = 90.962952;
      this.lat = data.lat ? Number(data.lat) : '';
      this.lng = data.lng ? Number(data.lng) : '';
      this.options = {
        ...this.options,
        center: this.getMapsLatLong(data),
      };
      this.markerPositions = [this.getMapsLatLong(data)];
    }

      this.restService.getPolicyFlags()
      .pipe(
        take(1),
        filter((v:any)=>!!v.configuration),
        map((resp)=>JSON.parse(resp.configuration)),
        mergeMap((resp)=>from(this.jsEncriptWrapperService.decryptData(resp.googleMapsWeb.adkey)).pipe((key)=>key))
      )
      .subscribe((key)=>{

      this.apiLoaded =  httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=" +
          key,
          "callback"
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
      )
      });

  }

  getMapsLatLong(data): google.maps.LatLngLiteral {
    return {
      lat: data.lat ? Number(data.lat) : null,
      lng: data.lng ? Number(data.lng) : null
    };
  }




  onCancellick(): void {
    this.dialogRef.close({test: 'asdasd'});
  }

}
