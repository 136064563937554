/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { ActivatedRoute } from '@angular/router';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

const activityTypeList = [
  { list_item: 'P' },
  { list_item: 'A' }
];
const activityList = [
  { list_item: 'RFD' }
];
const statusList = [
  { list_item: 'E' }
];
const flatTransactionList = [
  { list_item: 'Flat Free' },
  { list_item: '% of Transaction' }
];
const collectionMethodList = [
  { list_item: 'N' },
  { list_item: 'P' }
];

@Component({
  selector: 'app-add-transaction-fees-dialog',
  templateUrl: './add-transaction-fees-dialog.component.html',
  styleUrls: ['./add-transaction-fees-dialog.component.scss']
})
export class AddTransactionFeesDialogComponent implements OnInit {

  activityType: Array<any>;
  activity: Array<any>;
  status: Array<any>;
  flatTransaction: Array<any>;
  collectionMethod: Array<any>;

  submitted = false;
  policyId: number;
  addTransactionFeesForm: UntypedFormGroup;
  typeCheck: Array<any>;
  trasactionFee: any;

  constructor(
    public dialogRef: MatDialogRef<AddTransactionFeesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute
  ) {
    this.trasactionFee = {
      eventAction: '',
      eventLocation: '',
      eventMechanism: '',
      feeForStaus: '',
      collectType: '',
      status: ''
    };
  }

  ngOnInit() {
    this.GetActivityTypeAsyc();
    this.GetActivityAsyc();
    this.GetStatusAsyc();
    this.GetFlatTransactionAsyc();
    this.GetCollectionMethodAsyc();

    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
    });

    this.addTransactionFeesForm = this.formBuilder.group({
      ActivityType: ['', [Validators.required]],
      Activity: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      FlatTransaction: ['', [Validators.required]],
      FlatPercent: ['', [Validators.required, Validators.pattern('[0-9]*(.[0-9]{0,2})')]],
      CollectionMethod: ['', [Validators.required]],
      CollectFees: ['', [Validators.pattern('[0-9]*(.[0-9]{0,2})')]],
      EnabledType: [false]
    });
  }

  GetActivityTypeAsyc() {
    this.activityType = activityTypeList;
  }
  GetActivityAsyc() {
    this.activity = activityList;
  }
  GetStatusAsyc() {
    this.status = statusList;
  }
  GetFlatTransactionAsyc() {
    this.flatTransaction = flatTransactionList;
  }
  GetCollectionMethodAsyc() {
    this.collectionMethod = collectionMethodList;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addTransactionFeesForm.invalid) {
      return;
    }

    this.trasactionFee = {
      eventMechanism: this.addTransactionFeesForm.value.ActivityType,
      eventAction: this.addTransactionFeesForm.value.Activity,
      status: this.addTransactionFeesForm.value.Status,
      percentTransaction: this.addTransactionFeesForm.value.FlatTransaction,
      percentFee: this.addTransactionFeesForm.value.FlatPercent,
      collectType: this.addTransactionFeesForm.value.CollectionMethod,
      feeForStaus: this.addTransactionFeesForm.value.CollectFees,
      enabled: this.addTransactionFeesForm.value.EnabledType,
      programPolicyId: Number(this.policyId)
    };

    // tslint:disable-next-line: max-line-length
    this.typeCheck = this.data.some((e: any) => e.eventMechanism === this.trasactionFee.eventMechanism && e.eventAction === this.trasactionFee.eventAction);

    if (this.typeCheck) {
      this.customeSnakbar.openCustomSnakbar('Duplicate Activity Type, Activity and Status', '', 'success');
    } else {
      this.spinner.show();
      this.service.addTransactionFee(this.trasactionFee).subscribe((resp: any) => {
        this.spinner.hide();
        if (resp.status === 200) {
          this.customeSnakbar.openCustomSnakbar('Added Successfully', '', 'success');
          this.dialogRef.close();
        }
      });
    }
  }
}
