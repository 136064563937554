/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-balance-parameter',
  templateUrl: './balance-parameter.component.html',
  styleUrls: ['./balance-parameter.component.scss']
})
export class BalanceParameterComponent implements OnInit {
  submitted = false;
  updateBalanceForm: UntypedFormGroup;
  rowData = {} as any;
  policyId: number;
  accountId: number;
  accountParams: number;

  constructor(
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
      this.accountId = params.accountId;
      if (this.accountId === undefined) {
        // Program Policy
        this.GetPolicy();
      } else {
        // Account Policy
        this.GetAccountControls();
      }
    });
    this.updateBalanceForm = this.formBuilder.group({
      MaxBalance: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      Overage: ['', [Validators.required, Validators.pattern('[0-9]+')]]
    });
  }

  GetPolicy() {
    this.spinner.show();

    this.service.getPolicy(this.policyId).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.rowData = resp.body;
      }
    });
  }
  GetAccountControls() {
    this.spinner.show();

    this.service.getAccountControls(this.accountId).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.rowData = resp.body.data[0];
        this.accountParams = resp.body.data.length;
        if (this.rowData !== undefined) {
          // below line will be deprecated after sometimes
          this.rowData.maxBalance = this.rowData.balanceLimit;
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    // Stop if the form is invalid
    if (this.updateBalanceForm.invalid) {
      return;
    }

    this.spinner.show();

    if (this.accountId === undefined) {
      this.updateProgramPolicy();
    } else {
      this.updateOrAddAccountControls();
    }
  }

  updateProgramPolicy() {
    const rowData = {
      maxBalance: Number(this.updateBalanceForm.value.MaxBalance),
      balanceOverage: Number(this.updateBalanceForm.value.Overage),
    };

    this.service.updateProgramPolicy(rowData, this.policyId).subscribe((resp: any) => {
      this.spinner.hide();
      this.handleResponse(resp, 'Updated successfully');
    });
  }

  updateOrAddAccountControls() {
    const rowData = {
      entityId: Number(this.accountId),
      entityType: 'AP',
      balanceLimit: Number(this.updateBalanceForm.value.MaxBalance),
      balanceOverage: Number(this.updateBalanceForm.value.Overage),
    };

    const serviceCall = this.accountParams !== 0
      ? this.service.updateAccountControls(rowData)
      : this.service.addAccountControls(rowData);

    serviceCall.subscribe((resp: any) => {
      this.spinner.hide();
      const message = this.accountParams !== 0 ? 'Updated successfully' : 'Added successfully';
      this.handleResponse(resp, message);

      if (this.accountParams === 0) {
        this.GetAccountControls();
      }
    });
  }

  handleResponse(resp: any, message: string) {
    if (resp.status === 200) {
      this.customeSnakbar.openCustomSnakbar(message, '', 'success');
    }
  }

}
