/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from '../services/rest-services.service';
import { UuidManagerService } from '../services/uuid-manager.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private uuidManagerService:UuidManagerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return of(this.uuidManagerService.getLoginDeviceData()).pipe(
      mergeMap((securityInfo) => securityInfo),
      mergeMap((secInfo) => {
        // Get access token from Local Storage
        const accessToken = this.auth.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request

        if (accessToken) {
          request = request.clone({
            setHeaders: {
              Authorization: "Bearer " + accessToken,
              "security-info": `${secInfo}`,
            },
          });
        }

        return next.handle(request).pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) {
                // http response status code
                if (event.body && event.body.statusCode === 401) {
                  return this.logoutUser();
                }
              }
            },
            error: (error) => {
              if (error.message) {
                console.log(error.message);
              }
              if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                  case 400:
                    return this.handle400Error(error);
                  case 401:
                    return this.handle401Error(request, next);
                  case 500:
                    return throwError(()=>error);
                  default:
                    return throwError(()=>error);
                }
              }
            },
          })
        );
      })
    );
  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }
    return throwError(()=>error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    return this.logoutUser();
  }

  logoutUser() {
    const postData = {
      userId: Number(this.service.getUserId())
    };

    this.spinner.show();
    this.service.userLogout(postData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.auth.logout();
        this.router.navigate(['/login']);
      }
    });
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    // Get access token from Local Storage
    const accessToken = this.auth.getAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

     // We clone the request, because the original request is immutable

    return request.clone({
      setHeaders: { Authorization: 'Bearer ' + accessToken }
    });
  }
}
