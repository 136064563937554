/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[PhoneMask]",
})
export class PhoneMaskDirective {
  // tslint:disable-next-line: no-input-rename
  @Input("PhoneMask") PhoneMask = false;
  constructor(private el: ElementRef) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!this.PhoneMask || event === undefined || event === null) {
      return;
    }

    let newVal = event.replace(/\D/g, "");

    if (newVal.charAt(0) === "1") {
      newVal = newVal.replace("1", "");
    }

    const length = newVal.length;

    if (length === 0) {
      newVal = "";
    } else if (length < 3) {
      console.log(newVal);
    } else if (length < 7) {
      newVal = this.applyMask(newVal, backspace, length);
    } else {
      newVal = newVal.substring(0, 10);
      newVal = this.applyMask(newVal, backspace, 10);
    }

    this.el.nativeElement.value = newVal;
  }

  applyMask(newVal, backspace, length) {
    if (length === 3) {
      return backspace ? newVal : newVal + "-";
    } else if (length < 6) {
      return newVal.slice(0, 3) + "-" + newVal.slice(3);
    } else if (length === 6) {
      return backspace
        ? newVal
        : newVal.slice(0, 3) + "-" + newVal.slice(3) + "-";
    } else {
      return (
        newVal.slice(0, 3) + "-" + newVal.slice(3, 6) + "-" + newVal.slice(6)
      );
    }
  }
}
