<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mainCard">
  <mat-card-title class="sectionTitle">
   <span class="mat-card-title"> {{title}}</span>
  </mat-card-title>
  <mat-card-content>
    <div class="py-5">
      <h6 style="font-family: 'GOTHIC', serif, sans-serif; font-weight: 400;">{{text}}</h6>
    </div>
    <div class="button-row flex flex-row justify-start py-5">
      <button mat-raised-button (click)="cancelClick()" color="primary" cdkFocusInitial style="font-weight: 600;">OK</button>
    </div>
  </mat-card-content>
</mat-card>
