  <div class="transactionTable">
  <mat-form-field class="table-filter" *ngIf="showFilter">
    <mat-label>Search</mat-label>
    <input matInput class="table-filter" [(ngModel)]="filterText" (keydown.enter)="applyFilter()"
      (keyup)="applyFilter()">
  </mat-form-field>

  <mat-form-field class="table-dropdown" *ngIf="showColumnFilter">
    <mat-label>Columns</mat-label>
    <mat-select class="table-columns" [formControl]="columnsDropDown" multiple (selectionChange)="changeSelect($event)"
      [ngModel]="displayedColumns">
      <mat-select-trigger>
        {{columnsDropDown.value ? columnsDropDown.value[0] : ''}}
        <span *ngIf="columnsDropDown.value?.length > 1" class="example-additional-selection">
          (+{{columnsDropDown.value.length - 1}} {{columnsDropDown.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let column of allColumns" [value]="column.id">{{column.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-paginator *ngIf="showPaginator && paginationMode == 'server'" id="table-paginator" [pageSize]="pageSize"
    [pageSizeOptions]="pageOptions" showFirstLastButtons [pageIndex]="currentPage"
    (page)="pageEvent = getPaginatorData($event)" [length]="tableLength"></mat-paginator>
  <mat-paginator *ngIf="showPaginator && paginationMode != 'server'" [pageSize]="pageSize"
    [pageSizeOptions]="pageOptions" showFirstLastButtons>

  </mat-paginator>

  <div class="table-responsive">
    <table aria-describedby="search table" *ngIf="!isLoading" mat-table [dataSource]="dataSource" matSort
      (matSortChange)="sortChange($event)" class="table">

      <!-- Position Column -->
      <ng-container matColumnDef="action" class="right-content">
        <th mat-header-cell *matHeaderCellDef>

        </th>

        <td class="action" mat-cell *matCellDef="let row">
          <a *ngIf="row.menuIcon" [matMenuTriggerFor]="menu"><i class="m-ellipsis-v"></i></a>
          <mat-menu #menu="matMenu">
            <button *ngFor="let item of row.menuOptions" mat-menu-item
              (click)="menuItemClicked(item,row)">{{item.name}}</button>
          </mat-menu>

        </td>

      </ng-container>

      <ng-container matColumnDef="{{col.id}}" *ngFor="let col of allColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.disabled]="isSortingDisabled(col.id)"
          class="data-header" [ngClass]="{'text-orange': col.name == 'Coins'}">
          {{col.name}}
        </th>
        <td mat-cell *matCellDef="let element" class="data-column">
          <span *ngIf="!col.isAction">
            {{element[col.id]}}
          </span>
          <a href="javascript:void(0)" *ngIf="showActionLink(col,element)" (click)="openActionClicked(col.id,element)">
            {{col.dynamic ? element[col.id] : col.id}}
          </a>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <p *ngIf="allRows.length < 1" class="notFound"> No Record Found</p>
</div>
