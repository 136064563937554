/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ConfirmDialogComponent } from 'src/app/shared/shared-component/confirm-dialog/confirm-dialog.component';
import { CommonUtility } from 'src/app/utility/common.utility';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-add-transaction-limit-dialog',
  templateUrl: './add-transaction-limit-dialog.component.html',
  styleUrls: ['./add-transaction-limit-dialog.component.scss']
})
export class AddTransactionLimitDialogComponent implements OnInit, OnDestroy {
  periodType: Array<any>;
  merchantType = {} as any;

  submitted = false;
  policyId: number;
  accountId: number;
  addTransactionLimitForm: UntypedFormGroup;
  typeCheck: Array<any>;
  transactionLimit: any;

  obsMerchantTypedata = null;

  constructor(
    public dialogRef: MatDialogRef<AddTransactionLimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private dialog: MatDialog,
    private commonApi: CommonUtility
  ) {
    this.transactionLimit = {
      entityType: '',
      entityId: null,
      limitType: '',
      mccType: '',
      amount: null,
      count: null,
    };

    this.addTransactionLimitForm = this.formBuilder.group({
      MccType: ['', [Validators.required]],
      Period: ['', [Validators.required]],
      Amount: ['', CustomValidators.alphanumeric],
      Count: ['', Validators.pattern('[0-9]+')]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
      this.accountId = params.accountId;
    });

    if (this.obsMerchantTypedata === null) {
      this.obsMerchantTypedata = this.dataservice.obsMerchantTypedata.subscribe((resp: any) => {
        if (resp.length > 0) {
          this.merchantType = resp;
        } else {
          this.commonApi.GetMerchantTypeAsyc();
        }
      });
    }
    this.GetPeriodTypeAsyc();
  }

  GetPeriodTypeAsyc() {
    this.service.getPeriod().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200 && res.length > 0) {
        this.periodType = res.body.data;
      }
    });
  }

  ngOnDestroy() {
    if (this.obsMerchantTypedata) {
      this.obsMerchantTypedata.unsubscribe();
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addTransactionLimitForm.invalid) {
      return;
    }

    this.confirmation();
  }

  confirmation(): void {
    const message = 'Are you sure ?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message }
    });
    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.addCardStatus();
      }
    });
  }

  addCardStatus() {
    this.transactionLimit = {
      entityType: this.accountId === undefined ? 'PP' : 'AP',
      entityId: this.accountId === undefined ? Number(this.policyId) : Number(this.accountId),
      limitType: this.addTransactionLimitForm.value.Period,
      mccType: this.addTransactionLimitForm.value.MccType,
      amount: Number(this.addTransactionLimitForm.value.Amount),
      count: Number(this.addTransactionLimitForm.value.Count)
    };

    // tslint:disable-next-line: max-line-length
    this.typeCheck = this.data.some((e: any) => e.mccType === this.transactionLimit.mccType && e.limitType === this.transactionLimit.limitType);

    if (this.transactionLimit.amount === null && this.transactionLimit.count === null) {
      this.customeSnakbar.openCustomSnakbar('Either Amount or count should be entered', '', 'success');
    } else if (this.typeCheck) {
      this.customeSnakbar.openCustomSnakbar('Duplicate Merchant Type and Period', '', 'success');
    } else {
      this.spinner.show();
      this.service.addTransactionLimit(this.transactionLimit).subscribe((resp: any) => {
        this.spinner.hide();
        if (resp.status === 200) {
          this.customeSnakbar.openCustomSnakbar('Added Successfully', '', 'success');
          this.dialogRef.close(this.data.currentTab);
        }
      });
    }
  }
}
