import { map } from 'rxjs/operators';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { RestServicesService } from "src/app/services/rest-services.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonUtility } from 'src/app/utility/common.utility';
import { UtcDatePipe } from 'src/app/pipes/custom.pipe';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: "app-split-terminal",
  templateUrl: "./split-terminal.component.html",
  styleUrls: ["./split-terminal.component.scss"],
  providers: [UtcDatePipe]
})
export class SplitTerminalComponent implements OnInit {
  @ViewChild('DataSection',{static:true}) DataSection:ElementRef;
  @ViewChild('sbmtButton') sbmtButton:ElementRef;
  dynamicFormGroup: FormGroup;
  response: any = [];
  command: any = {
    command: "account/global/_default",
    entityTypeId: "global",
  };
  backTickRegex = /`/g;
  selectRegex = /_select/g;
  resetResponse: boolean = false;
  maxDate = new Date();
  minDate = new Date();
  submitted: boolean = false;

  constructor(
    private restService: RestServicesService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    protected commonUtilityService: CommonUtility,
    private utcDate: UtcDatePipe,
    protected customeSnakbar: CustomSnakbarComponent,
    private spinner:NgxSpinnerService
  ) {}
  ngOnInit(): void {
    this.createDynamicFormGroup();
    this.route.queryParams.subscribe((params) => {
      if (params["email"]) {
        const payload = {
          command: "account/PA/_party_search_result",
          entityTypeId: "global:",
          parameters: {
            email: params["email"],
          },
        };
        this.command = payload;
        this.executeCommand();
      } else {
        this.executeCommand();
      }
    });
  }

  createDynamicFormGroup() {
    this.dynamicFormGroup = this.fb.group({
      command: [null],
      entityTypeId: [null],
    });
  }

  executeCommand() {
    this.spinner.show();
    this.restService.scriptQuery(this.command).pipe(
      map((res: any) => this.formatResponseData(res.body.sections))
    )
    .subscribe((data: any) => {
      this.spinner.hide();
      this.handleResponseData(data);
    });
  }

  formatResponseData(sections: any[]): any[] {
    return sections.map((sec) => {
      let sectionData = { section: sec.section, data: [], form: null };

      switch (sec.section) {
        case "DATA":
        case "COPY":
          sectionData.data = sec.data;
          break;
        case "SELECT":
          if (sec.data) {
            sectionData.data = sec.data;
          }
          break;
        case "FORM":
          if (sec.data) sectionData.data = sec.data;
          if (sec.form) sectionData.form = sec.form;
          this.patchForm(sec.form);
          break;
        default:
          sectionData.data = this.formatCustomSection(sec);
      }
      return sectionData;
    });
  }

  patchForm(form: any): void {
    if (!form) return;

    this.dynamicFormGroup.get("command").patchValue(form.command);
    this.dynamicFormGroup.get("entityTypeId").patchValue(form.entityTypeId);

    form.parameters.forEach((formCtrl: any) => {
      const control = formCtrl.required
        ? this.fb.control("", [Validators.required])
        : this.fb.control(null);

      this.dynamicFormGroup.addControl(formCtrl.field, control);

      if (formCtrl.value) {
        this.patchFormValue(formCtrl);
      }
    });
  }

  patchFormValue(formCtrl: any): void {
    if (formCtrl.fieldType.toLowerCase() === "date") {
      this.dynamicFormGroup.get(formCtrl.field).patchValue(new Date(formCtrl.value));
    } else {
      this.dynamicFormGroup.get(formCtrl.field).patchValue(formCtrl.value);
    }
  }

  formatCustomSection(sec: any): any[] {
    return sec.data.map((secData: string) => {
      const commands = this.findStringWithBackTick(secData)
        .map((c) => c.split("|"))
        .filter((f) => f !== "");

      const verbiages = secData.split("`").filter((part, index) => index % 2 === 0 && part.trim() !== "");

      return {
        commands: commands,
        verbiages: verbiages
      };
    });
  }

  handleResponseData(data: any[]): void {
    if (data.find((dt) => dt.section === 'DATA')) {
      (this.DataSection.nativeElement as HTMLElement).scrollTop = 0;
    }

    if (this.response.length <= 0 || this.resetResponse) {
      this.response = this.resetResponse ? this.updateExistingResponse(data) : data;
      this.resetResponse = false;
    } else {
      this.mergeResponseData(data);
    }
  }

  updateExistingResponse(data: any[]): any[] {
    return this.response.map((res, i) => {
      const matchedData = data.find((dt) => dt.section === res.section);
      let newValue;
      if (matchedData) {
        newValue = matchedData;
      } else {
        newValue = res.section !== "COPY" ? {} : res;
      }
      return newValue;
    });
  }

  mergeResponseData(data: any[]): void {
    data.forEach((v) => {
      const existingIndex = this.response.findIndex((resp) => resp.section === v.section);

      if (existingIndex !== -1 && v.section === "COPY") {
        this.response.push(v);
      } else if (existingIndex !== -1) {
        this.response[existingIndex] = v;
      } else {
        this.response.push(v);
      }
    });
  }


  findStringWithBackTick(text: string): any {
    const regex = /`([^`]*)`/g;

    let matches;
    const result = [];

    while ((matches = regex.exec(text)) !== null) {
      result.push(matches[1]);
    }
    return result;
  }

  callApi(
    command: any,
    entityType: any,
    section?: string,
    strData?: string,
    label?: string
  ) {
    if (label && label.toLowerCase() === "remove") {
      const copySectionSFiltersArr = this.response.filter(
        (res) => res.section === "COPY"
      );
      const copySectionFitered = copySectionSFiltersArr.find((v) =>
        v.data.find((e) => e === strData)
      );
      const index = this.response.findIndex((s) => s === copySectionFitered);
      this.response.splice(index, 1);
    } else {
      this.command = {
        command: command,
        entityTypeId: entityType,
      };
      this.executeCommand();
    }
  }

  submit(event) {
    this.submitted = true;

    if (!this.isFormValid()) return false;

    if (event) {
      this.patchCommandValue(event);
    }

    const payload = this.buildPayload();
    this.command = payload;
    this.executeCommand();
  }

  isFormValid(): boolean {
    if (this.dynamicFormGroup.invalid) {
      this.dynamicFormGroup.updateValueAndValidity();
      return false;
    }
    return true;
  }

  patchCommandValue(event): void {
    this.dynamicFormGroup.get("command").patchValue(event.submitter.value);
  }

  buildPayload() {
    const formValue = this.dynamicFormGroup.getRawValue();
    const parameters = this.extractParameters(formValue);

    return {
      command: formValue.command,
      entityTypeId: formValue.entityTypeId,
      parameters
    };
  }

  extractParameters(formValue): any {
    const parameters = {};
    this.response.forEach((res) => {
      if (res.section === "FORM") {
        res.form.parameters.forEach((formCtrl) => {
          parameters[formCtrl.field] = this.processFormControl(formCtrl, formValue);
        });
      }
    });
    return parameters;
  }

  processFormControl(formCtrl, formValue) {
    if (formCtrl.fieldType.toLowerCase() === "date") {
      return formValue[formCtrl.field] ? this.utcDate.transform(formValue[formCtrl.field]) : "";
    } else if (formCtrl.fieldType.toLowerCase() === "copy") {
      return this.patchValueInCopyField();
    } else {
      return formValue[formCtrl.field] ? formValue[formCtrl.field].toString() : "";
    }
  }


  checkTab(val: string) {
    return val.includes("\t");
  }
  checkAtRate(val: string) {
    return val.includes("@@@");
  }

  checkBackTick(val: string) {
    return val.includes("`");
  }

  executeInitialQuery() {
    const initPayload = {
      command: "account/global/_default",
      entityTypeId: "global",
    };
    this.command = initPayload;
    this.resetResponse = true;
    this.executeCommand();
  }

  clearCopySection() {
    this.response = this.response.filter((v) => v.section !== "COPY");
  }

  checkCopySection(): boolean {
    return this.response.filter((v) => v.section === "COPY").length > 0;
  }

  checkFormSection(): boolean {
    return this.response.filter((v) => v.section === "FORM").length > 0;
  }

  copyToClipBoard(data: any) {
    const dis = this;
    let modifiedData = [];
    if (data && data.length) {
      data.forEach((v, i) => {
        if (dis.checkTab(v) && dis.checkBackTick(v)) {
          const splitedData = v.split("\t");
          const filteredData =  splitedData.filter((u)=>!this.checkBackTick(u));
          modifiedData.push(filteredData.join());
        }
      });
    }
    return modifiedData.join('\r\n');
  }

  patchValueInCopyField():string{
    if(this.checkCopySection()){
      const entityTypeArry = [];
      this.response.filter((f)=>f.section === 'COPY').map((v)=>{
        entityTypeArry.push(v.data[0].split('\t')[0].split('|')[1]);
      })
      return entityTypeArry.join(',');
    }else{
      return '';
    }
  }


  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if(this.checkFormSection()){
      (this.sbmtButton?.nativeElement as HTMLElement).click();
    }
    event.preventDefault();
  }


// for getting the backtick string
   getSubstringInBackticks(str) {
    const startIndex = str.indexOf('`');
    if (startIndex === -1) {
      return null; // No backticks found
    }

    const endIndex = str.indexOf('`', startIndex + 1);
    if (endIndex === -1) {
      return null; // Unclosed backtick
    }

    return str.substring(startIndex + 1, endIndex);
  }

  trackByFn(index: number, item: any): any {
    return item.section || index; // Replace 'id' with a unique identifier
  }

  trackByDt(index: number, item: any): any {
    return  item || index; // Replace 'id' with a unique identifier
  }


}
