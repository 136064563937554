/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CommonUtility } from 'src/app/utility/common.utility';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-set-security-questions',
  templateUrl: './set-security-questions.component.html',
  styleUrls: ['./../login/login.component.scss']
})
export class SetSecurityQuestionsComponent implements OnInit, AfterViewInit {

  setSecurityForm: UntypedFormGroup;
  submitted = false;
  selectItems: any;
  allQuesList = [];
  listQuestion1 = [];
  listQuestion2 = [];
  listQuestion3 = [];
  selectedQuestion1 = '';
  selectedQuestion2 = '';
  selectedQuestion3 = '';
  selectedAnswer1 = '';
  selectedAnswer2 = '';
  selectedAnswer3 = '';
  QM: any = {};
  username = '';
  userid = null;
  hide = false;
  hide2 = false;
  hide3 = false;

  constructor(
    private service: RestServicesService,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    public customeSnakbar:CustomSnakbarComponent,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.setSecurityForm = this.fb.group({
      securityQuestion1: ['', [Validators.required]],
      securityAnswer1: ['', [Validators.required, CustomValidators.alphanumeric]],
      securityQuestion2: ['', [Validators.required]],
      securityAnswer2: ['', [Validators.required, CustomValidators.alphanumeric]],
      securityQuestion3: ['', [Validators.required]],
      securityAnswer3: ['', [Validators.required, CustomValidators.alphanumeric]]
    });
  }

  ngOnInit() {
    this.username = this.service.getUserName();
    this.userid = Number(this.service.getUserId());
    this.setQuestionsAndAnswers();
  }

  ngAfterViewInit() {
    this.auth.setExernalAuth('false');
    this.auth.getAppMode().removeItem('username');
  }

  setQuestionsAndAnswers() {
    this.spinner.show();
    this.requestDataFromMultipleSources().subscribe(responseList => {
      this.spinner.hide();

      this.allQuesList = responseList[0].body.data;
      this.listQuestion1 = this.filterQuestionList(this.QM.selectedQuestion2, this.QM.selectedQuestion3);
      this.listQuestion2 = this.filterQuestionList(this.QM.selectedQuestion1, this.QM.selectedQuestion3);
      this.listQuestion3 = this.filterQuestionList(this.QM.selectedQuestion1, this.QM.selectedQuestion2);
    });
  }
  onChangeQuestion(question, type) {
    switch (type) {
      case 1:
        this.QM.selectedQuestion1 = question.value;
        this.listQuestion2 = this.filterQuestionList(this.QM.selectedQuestion1, this.QM.selectedQuestion3);
        this.listQuestion3 = this.filterQuestionList(this.QM.selectedQuestion1, this.QM.selectedQuestion2);
        break;
      case 2:
        this.QM.selectedQuestion2 = question.value;
        this.listQuestion1 = this.filterQuestionList(this.QM.selectedQuestion2, this.QM.selectedQuestion3);
        this.listQuestion3 = this.filterQuestionList(this.QM.selectedQuestion2, this.QM.selectedQuestion1);
        break;
      case 3:
        this.QM.selectedQuestion3 = question.value;
        this.listQuestion1 = this.filterQuestionList(this.QM.selectedQuestion3, this.QM.selectedQuestion2);
        this.listQuestion2 = this.filterQuestionList(this.QM.selectedQuestion3, this.QM.selectedQuestion1);
        break;
    }
  }

  filterQuestionList(otherQues1, otherQues2) {
    return this.allQuesList.filter((ele) => {
      ele.value = ele.description;
      ele.id = ele.description;
      return ele.id !== otherQues1 && ele.id !== otherQues2;
    });
  }

  requestDataFromMultipleSources(): Observable<any[]> {
    const request2 = this.service.getSecurityQuestions();
    return forkJoin([request2]);
  }

  get f() { return this.setSecurityForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.setSecurityForm.invalid) {
      return;
    }

    const postData = Object.assign({});

    postData.securityQuestion1 = this.QM.selectedQuestion1;
    postData.securityQuestion2 = this.QM.selectedQuestion2;
    postData.securityQuestion3 = this.QM.selectedQuestion3;
    postData.securityAnswer1 = this.f.securityAnswer1.value;
    postData.securityAnswer2 = this.f.securityAnswer2.value;
    postData.securityAnswer3 = this.f.securityAnswer3.value;
    this.updatQuestionAnswer(postData);
  }
  updatQuestionAnswer(postData) {
    this.spinner.show();

    this.service.adminUpdateUser(postData).subscribe((userResp: HttpResponse<any>) => {
      this.spinner.hide();
      if (userResp.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Updated Successfully!', '', 'success');

        // Profile Required
        const profileRequired = this.auth.getProfileRequired();
        const nextCode = CommonUtility.getNextRouteCode(profileRequired, 'SQ');

        if (nextCode !== '') {
          this.auth.setExernalAuth('true');
        } else {
          this.auth.deleteProfileRequired();
        }
        const nextRoute = CommonUtility.getFirstLoginRoute(nextCode);
        this.router.navigate([nextRoute]);
      }
    });
  }

}
