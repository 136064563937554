/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { RestServicesService } from '../services/rest-services.service';
import { CustomSnakbarComponent } from '../shared/shared-component/custom-snakbar/custom-snakbar.component';
import { DateUtility } from './date.utility';

@Injectable({
  providedIn: "root",
})
export class CommonUtility {
  constructor(
    private dataservice: DataService,
    private service: RestServicesService,
    private customeSnakbar: CustomSnakbarComponent
  ) {}

  static getConvertedAmount(amount) {
    if (amount && !isNaN(amount)) {
      amount = Number(amount) / 100;
    }
    return amount;
  }
  static setConvertedAmount(amount) {
    if (amount && !isNaN(amount)) {
      amount = Number(amount) * 100;
    }
    return amount;
  }
  static getFirstLoginRoute(val) {
    let route = "/admin/dashboard";
    switch (val) {
      case "PWD":
        route = "/setPassword";
        break;
      case "SQ":
        route = "/setSecurityQuestions";
        break;
    }
    return route;
  }
  static getNextRouteCode(profileRequired: string, cScreen) {
    let arry = profileRequired.split(",");
    arry = arry.map((ele) => {
      return ele.trim();
    });

    let nextCode = "";
    const cindex = arry.indexOf(cScreen);

    if (arry.length - 1 > cindex) {
      nextCode = arry[cindex + 1];
    }
    return nextCode;
  }
  static covertDateToYMD(date) {
    if (!date || date === "") {
      return "";
    }

    if (typeof date === "string") {
      return date.includes("T") ? date.split("T")[0] : date;
    }

    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  static formatTelephone(anyNumber) {
    if (anyNumber && anyNumber.length > 9) {
      anyNumber = anyNumber.replace(/-/g, "");
      anyNumber = anyNumber.replace(/ /g, "");
      anyNumber = [
        anyNumber.slice(0, 3),
        "-",
        anyNumber.slice(3, 6),
        "-",
        anyNumber.slice(6, 10),
      ].join("");
    }
    return anyNumber;
  }
  static removeFormatTelephone(anyNumber) {
    if (anyNumber) {
      anyNumber = anyNumber.replace(/-/g, "");
      anyNumber = anyNumber.replace(/ /g, "");
    }
    return anyNumber;
  }
  static isEmptyObj(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  static getFirstDayOfMonth() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    return firstDay;
  }
  static getPreviousDayDate(days) {
    const date = new Date();
    const lastDate = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    return lastDate;
  }
  static getFirstDayOfLastMonth() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m - 1, 1);
    return firstDay;
  }
  static getLastDayOfLastMonth() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const lastDay = new Date(y, m, 1);
    return lastDay;
  }
  static getNextDayDate(date) {
    const d = new Date(date);
    return d.setDate(d.getDate() + 1);
  }
  static getPeriodQuery(periodType, range?) {
    let periodFilter = "";
    let startDate: any;
    let currentDay: any;
    currentDay = new Date().toISOString();
    const tzoffset = new Date().getTimezoneOffset() * 60000;
    switch (periodType) {
      case "CM":
        startDate = this.getFirstDayOfMonth();
        startDate = new Date(startDate - tzoffset).toISOString();
        break;
      case "LM":
        startDate = this.getFirstDayOfLastMonth();
        startDate = new Date(startDate - tzoffset).toISOString();
        currentDay = this.getLastDayOfLastMonth();
        currentDay = new Date(currentDay - tzoffset).toISOString();
        break;
      case "L30":
        startDate = this.getPreviousDayDate(30);
        startDate.setUTCHours(0, 0, 0, 0);
        startDate = startDate.toISOString();
        break;
      case "range":
        startDate = range.startDate;
        startDate = new Date(startDate - tzoffset).toISOString();
        currentDay = this.getNextDayDate(range.endDate);
        currentDay = new Date(currentDay - tzoffset).toISOString();
    }

    periodFilter = `,T:${startDate},T:${currentDay}`;
    return periodFilter;
  }
  static getPeriodQueryYMD(periodType) {
    const periodFilter = "";
    let startDate: any;
    let currentDay: any;
    const date = new Date();
    const y = date.getFullYear();
    currentDay = this.covertDateToYMD(new Date());
    switch (periodType) {
      case "CM":
        startDate = DateUtility.getFirstDayOfMonth();
        startDate = this.covertDateToYMD(startDate);
        break;
      case "L30":
        startDate = DateUtility.getPreviousDayDate(30);
        startDate = this.covertDateToYMD(startDate);
        break;
      case "LM":
        startDate = DateUtility.getFirstDayOfLastMonth();
        startDate = this.covertDateToYMD(startDate);
        const m = date.getMonth();
        currentDay = new Date(y, m, 0);
        currentDay = this.covertDateToYMD(currentDay);
        break;
      case "CY":
        startDate = DateUtility.getFirstDayOfYear();
        startDate = this.covertDateToYMD(startDate);
        currentDay = new Date(y, 12, 0);
        currentDay = this.covertDateToYMD(currentDay);
        break;
    }
    return { startDate, endDate: currentDay };
  }

  GetMerchantTypeAsyc() {
    this.service.getMerchantType().subscribe((resp: any) => {
      const merchantType = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.merchantType(merchantType);
    });
  }
  GetMerchantNameAsyc() {
    this.service.getMerchantName().subscribe((resp: any) => {
      const merchantName = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.merchantName(merchantName);
    });
  }
  GetAmountTypeAsyc() {
    this.service.getAmountType().subscribe((resp: any) => {
      const amountType = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.amounttype(amountType);
    });
  }
  GetActionTypeAsyc() {
    this.service.getAction().subscribe((resp: any) => {
      const actionType = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.action(actionType);
    });
  }
  GetSendAlertAsyc() {
    this.service.getSendAlert().subscribe((resp: any) => {
      const sendAlert = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.sendAlert(sendAlert);
    });
  }
  GetAllowedAsyc() {
    this.service.getAllowed().subscribe((resp: any) => {
      const allowedType = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.allowed(allowedType);
    });
  }
  GetCardList() {
    this.service.getCards().subscribe((resp: any) => {
      const cardList = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.setCardListData(cardList);
    });
  }
  GetCountries() {
    this.service.getCountriesList().subscribe((resp: any) => {
      const countryLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.setCountryData([].concat(countryLookup));
    });
  }
  GetRole() {
    this.service.getRoles().subscribe((resp: any) => {
      const roleLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.roles(roleLookup);
    });
  }
  GetApps() {
    this.service.getApps().subscribe((resp: any) => {
      const appLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.apps(appLookup);
    });
  }
  GetAppTypes() {
    this.service.getAppTypes().subscribe((resp: any) => {
      const apptypeLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.apptypes(apptypeLookup);
    });
  }
  GetAllBanks() {
    this.service.getAllBanks().subscribe((resp: any) => {
      const banksLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.banks(banksLookup);
    });
  }
  GetSources() {
    this.service.getSources().subscribe((resp: any) => {
      const sourceLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.sources(sourceLookup);
    });
  }
  GetEventAction() {
    this.service.getEventActions().subscribe((resp: any) => {
      const eventActionLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.eventActions(eventActionLookup);
    });
  }

  GetCardsStatus() {
    this.service.getCardsStatus().subscribe((resp: any) => {
      const cardsStatusLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.cardsStatus(cardsStatusLookup);
    });
  }

  GetPartyStatus() {
    this.service.getPartyStatus().subscribe((resp: any) => {
      const partyStatusLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.partyStatus(partyStatusLookup);
    });
  }

  GetTransactionStatus() {
    this.service.getTransactionStatus().subscribe((resp: any) => {
      const transactionStatusLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.transactionStatus(transactionStatusLookup);
    });
  }
  GetEntityType() {
    this.service.getEntityType().subscribe((resp: any) => {
      if (resp.body.data.length > 0) {
        const entityTypeLookup = resp.body.data.map((ele) => {
          return ele;
        });
        this.dataservice.entityType(entityTypeLookup);
      }
    });
  }
  GetBusinessType() {
    this.service.getBusinessType().subscribe((resp: any) => {
      if (resp.body.data.length > 0) {
        const businessTypeLookup = resp.body.data.map((ele) => {
          return ele;
        });
        this.dataservice.businessType(businessTypeLookup);
      }
    });
  }
  GetFrequencyData() {
    this.service.getFrequencyLookup().subscribe((resp: any) => {
      const frequencyLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.frequencyLookupData(frequencyLookup);
    });
  }
  GetFlagsData() {
    this.service.getPolicyFlags().subscribe((resp: any) => {
      const flags = JSON.parse(resp.configuration);
      this.dataservice.setFlagsData(flags);
    });
  }
  GetSuffixData() {
    this.service.getSuffix().subscribe((resp: any) => {
      const suffixLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.suffixLookupData(suffixLookup);
    });
  }
  GetTitlesData() {
    this.service.getTitles().subscribe((resp: any) => {
      const titlesLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.titlesLookupData(titlesLookup);
    });
  }
  GetKYCStatus() {
    this.service.getKYCStatus().subscribe((resp: any) => {
      const kycStatusLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.kycStatus(kycStatusLookup);
    });
  }
  GetOFACStatus() {
    this.service.getOFACStatus().subscribe((resp: any) => {
      const ofacStatusLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.ofacStatus(ofacStatusLookup);
    });
  }
  GetDocMetadata() {
    this.service.getDocMetadataLookup().subscribe((resp: any) => {
      const docMetadataLookup = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.docMetadata(docMetadataLookup);
    });
  }
  GetShipTypeLookupData() {
    this.service.getShipTypeLookup().subscribe((resp: any) => {
      const shipTypeList = resp.body.data.map((ele) => {
        return ele;
      });
      this.dataservice.setShipTypeList(shipTypeList);
    });
  }

  GetFraudStatusTypeLookupData() {
    this.service.getFraudStatus().subscribe((res: any) => {
      this.dataservice.setFraudStatusListData(res.body.data);
    });
  }

  keyPress($event) {
    let newVal = $event.target.value;
    if ($event.keyCode !== 8) {
      newVal = newVal.replace(/\D/g, "");
      if (newVal.length === 0) {
        newVal = "";
      } else if (newVal.length < 2) {
        newVal = newVal.replace(/^(\d{0,2})/, "$1");
      } else if (newVal.length === 2) {
        newVal = newVal.replace(/^(\d{0,2})/, "$1/");
      } else if (newVal.length < 4) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, "$1/$2");
      } else if (newVal.length === 4) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, "$1/$2/");
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, "$1/$2/$3");
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, "$1/$2/$3");
      }
      $event.target.value = newVal;
    }
  }
}
