/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddSingleTransactionDialogComponent } from '../add-single-transaction-dialog/add-single-transaction-dialog.component';
import { UpdateSingleTransactionDialogComponent } from '../update-single-transaction-dialog/update-single-transaction-dialog.component';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { RemoveControlComponent } from 'src/app/shared/shared-component/remove-control/remove-control.component';
import { CommonUtility } from 'src/app/utility/common.utility';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-single-transaction',
  templateUrl: './single-transaction.component.html',
  styleUrls: ['./single-transaction.component.scss']
})
export class SingleTransactionComponent implements OnInit, OnDestroy {
  dataSource = [] as any;
  policyId: number;
  accountId: number;

  merchantType = {} as any;
  singleTransaction = {} as any;
  transaction = {} as any;
  control = 'txnmax';

  obsMerchantTypedata = null;

  paginationConfig = {
    CurrentPage: 1,
    ItemsPerPage: 5,
    TotalItems: 0,
    Offset: 0
  };
  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private customeSnakbar:CustomSnakbarComponent,
    private commonApi: CommonUtility
  ) { }

  ngOnInit() {
    if (this.obsMerchantTypedata === null) {
      this.obsMerchantTypedata = this.dataservice.obsMerchantTypedata.subscribe((resp: any) => {
        if (resp.length > 0) {
          resp.forEach((v: any, k: any) => {
            this.merchantType[v.code] = v.description;
          });
        } else {
          this.commonApi.GetMerchantTypeAsyc();
        }
      });
    }

    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
      this.accountId = params.accountId;
      if (this.accountId === undefined) {
        this.GetSingleTransaction();
      } else {
        this.GetAccountControls();
      }
      this.GetActions();
    });
  }

  ngOnDestroy() {
    if (this.obsMerchantTypedata) {
      this.obsMerchantTypedata.unsubscribe();
    }
  }

  pageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    if (this.accountId === undefined) {
      this.GetSingleTransaction();
    } else {
      this.GetAccountControls();
    }
  }

  GetSingleTransaction() {
    this.spinner.show();

    this.service.getSingleTransaction(this.policyId, 'PP').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.paginationConfig.TotalItems = res.body.data.length;
        this.dataSource = res.body.data;
      }
    });
  }
  GetAccountControls() {
    this.spinner.show();

    this.service.getSingleTransaction(this.accountId, 'AP').subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.dataSource = resp.body.data;
      }
    });
  }

  GetActions() {
    const payload = {
      paths: ['programpolicy.control']
    };
    this.spinner.show();
    this.service.getActions(payload).subscribe((menuResp: any) => {
      this.spinner.hide();
      if (menuResp.status === 200) {
        if (menuResp.body.allowedPaths[0].subPath) {
          const newPolicy = {} as any;
          menuResp.body.allowedPaths[0].subPath.forEach((element: any) => {
            if (element.action !== null) {
              const tempKey = element.path.split('.')[1];
              newPolicy[tempKey] = element.action;
              newPolicy.control.split(',').forEach((data: any, index: any) => {
                this.transaction['policyOption' + data] = data;
              });
            }
          });
        }
      }
    });
  }

  addSingleTransactionDialog(): void {
    const dialogRef = this.dialog.open(AddSingleTransactionDialogComponent, {
      width: '50%',
      data: this.dataSource
    });
    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        if (this.accountId === undefined) {
          this.GetSingleTransaction();
        } else {
          this.GetAccountControls();
        }
      }
    });
  }

  updateSingleTransactionDialog(data: any, index: number): void {
    const dialogRef = this.dialog.open(UpdateSingleTransactionDialogComponent, {
      width: '50%',
      data: { source: Object.assign({}, data), index }
    });
    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        if (this.accountId === undefined) {
          this.GetSingleTransaction();
        } else {
          this.GetAccountControls();
        }
      }
    });
  }

  removeSingleTransactionDialog(data: any, index: number): void {
    this.singleTransaction = this.createSingleTransaction(data);

    const message = 'Are you sure you want to remove this single transaction rule?';
    const okFlag = 'Remove';

    const dialogRef = this.dialog.open(RemoveControlComponent, {
      data: { message, index, okFlag }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.removeTransaction();
      }
    });
  }

  createSingleTransaction(data: any) {
    return {
      mccType: data.mccType,
      amount: Number(data.amount),
      entityType: this.accountId === undefined ? 'PP' : 'AP',
      entityId: this.accountId === undefined ? Number(this.policyId) : Number(this.accountId)
    };
  }

  removeTransaction(): void {
    this.spinner.show();
    this.service.deleteControl(this.control, this.singleTransaction).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
        this.updateAfterRemoval();
      }
    });
  }

  updateAfterRemoval(): void {
    if (this.accountId === undefined) {
      this.GetSingleTransaction();
    } else {
      this.GetAccountControls();
    }
  }

}
