/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-control',
  templateUrl: './remove-control.component.html',
  styleUrls: ['./remove-control.component.scss']
})
export class RemoveControlComponent implements OnInit {

  message: string;
  okFlag: string;

  constructor(
    public dialogRef: MatDialogRef<RemoveControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
    this.okFlag = data.okFlag;
  }

  ngOnInit() {
    console.log('component initialize')
  }

  removeClick() {
    this.dialogRef.close({ test: 'test' });
  }

  cancelClick() {
    this.dialogRef.close();
  }

}
