/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[SsnMask]",
})
export class SsnMaskDirective {
  // tslint:disable-next-line: no-input-rename
  @Input("SsnMask") SsnMask = false;
  constructor(private el: ElementRef) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!this.SsnMask || event === undefined) {
      return;
    }

    let newVal = event.replace(/\D/g, "");
    newVal = this.formatSSN(newVal, backspace);
    this.el.nativeElement.value = newVal;
  }

  formatSSN(newVal, backspace) {
    const length = newVal.length;

    if (length === 0) {
      return "";
    }

    let formattedVal = newVal;

    if (length < 3) {
      return formattedVal; // No formatting needed for less than 3 digits
    }

    formattedVal = this.applyMask(formattedVal, length, backspace);

    if (length > 9) {
      formattedVal = formattedVal.substring(0, 11); // Max length for SSN is 11 characters (xxx-xx-xxxx)
    }

    return formattedVal;
  }

  applyMask(newVal, length, backspace) {
    if (length === 3) {
      return backspace ? newVal : newVal + "-";
    } else if (length < 5) {
      return newVal.slice(0, 3) + "-" + newVal.slice(3);
    } else if (length === 5) {
      return backspace
        ? newVal
        : newVal.slice(0, 3) + "-" + newVal.slice(3) + "-";
    } else {
      return (
        newVal.slice(0, 3) + "-" + newVal.slice(3, 5) + "-" + newVal.slice(5, 9)
      );
    }
  }
}
