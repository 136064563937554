/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-left-menu',
  templateUrl: './custom-left-menu.component.html',
  styleUrls: ['./custom-left-menu.component.scss']
})
export class CustomLeftMenuComponent {

  // tslint:disable-next-line: no-input-rename
  @Input('panels') panels: Array<any> = [];


}
