/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  User,
  ForgotPassword
} from '../app-dto/rules.dto';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import {Observable} from 'rxjs';
import {InnerBankListCustomerSearchRequest} from '../models/bank.interface';
import {filter, shareReplay, take, tap} from 'rxjs/operators';
import {ExternalBankAccountListing, ExternalBankAccountListingRequest, InstrumentDetails} from '../shared/model/instrument';
import { DocumentFetchRequest } from '../models/core.interface';
import { DataService } from './data.service';
import { TransactionReqObj } from '../service-interface/interface-list';
@Injectable({
  providedIn: 'root'
})
export class RestServicesService {
  configObservable = null;
  userUrl: string = '';
  docUrl: string = '';
  statementUrl: string = '';
  accountUrl: string = '';
  bankUrl: string = '';
  reportUrl: string = '';
  solrUrl: string = '';
  glapiUrl:string = '';
  version: string = environment.version;
  version2:string = environment.version2;
  version3:string = environment.version3;
  baseUrl:string = '';


  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private auth: AuthService,
    private dataService:DataService
  ) {
    this.getConfigDetail();
  }

  getConfigDetail() {
    this.configObservable = this.configService.configObservable.pipe(
      take(1),
      filter((res)=>!!res)
    ).subscribe((resp: any) => {

      this.userUrl = resp.user_api_url === '' ? this.auth.getEndpoints().user_url : resp.user_api_url;
      this.docUrl = resp.doc_api_url === '' ? this.auth.getEndpoints().doc_url : resp.doc_api_url;
      this.statementUrl = resp.statement_api_url === '' ? this.auth.getEndpoints().statement_url : resp.statement_api_url;
      this.accountUrl = resp.account_api_url === '' ? this.auth.getEndpoints().account_url : resp.account_api_url;
      this.bankUrl = resp.bank_api_url === '' ? this.auth.getEndpoints().bank_url : resp.bank_api_url;
      this.reportUrl = resp.report_api_url === '' ? this.auth.getEndpoints().report_url : resp.report_api_url;
      this.solrUrl = resp.solr_api_url === '' ? this.auth.getEndpoints().solr_url : resp.solr_api_url;
      this.glapiUrl = resp.gl_api_url === '' ? this.auth.getEndpoints().gl_url : resp.gl_api_url;
      this.dataService.setCongigLoaded(true);


    });
  }

  getBaseUrl() {
    this.configService.baseUrlObservable.subscribe((v)=>{
      this.baseUrl = v;
    });
  }



  login(user: User) {
    return this.http.post(this.accountUrl + this.version2 + '/adminuser/login', user, { observe: 'response' });
  }
  userLogout(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/adminuser/logout', data, { observe: 'response' });
  }
  validateUser(data) {
    return this.http.put(this.accountUrl + this.version2 + '/adminuser/validate', data, { observe: 'response' });
  }
  validateOtp(data) {
    return this.http.put(this.accountUrl + this.version3 + '/user/validateotp', data, { observe: 'response' });
  }
  adminForgotPassword(user: ForgotPassword) {
    return this.http.put(this.accountUrl + this.version2 + '/adminuser/generateotp', user, { observe: 'response' });
  }
  forgotPassword(user: ForgotPassword) {
    return this.http.put(this.accountUrl + this.version2 + '/user/generateotp', user, { observe: 'response' });
  }

  userGet(username?: any) {
  const  payload = {
username
    }
    return this.http.put(this.accountUrl + this.version2 + `/adminuser/byusername`,payload, { observe: 'response' });
  }
  userGetByPartyId(id: any, page: any) {

   let payload = {
    queryName:'byPartyId',
   values:`L:${id}`,
    limit:(page.ItemsPerPage).toString(),
    offset:(page.Offset).toString(),
    sort:'id:desc'
   }
   return this.http.put(this.accountUrl + this.version2 + `/users/query`,payload, { observe: 'response' });
  }

  adminPartyGet(id: any) {

    return this.http.put(this.accountUrl + this.version2 + `/adminparty/get`,{}, { observe: 'response' });
  }
  adminUpdateProfile(data: any, id: any) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/adminparty/update`, data, { observe: 'response' });
  }
  updateProfile(data: any, id: any) {
    data.id = +id;
    return this.http.put(this.accountUrl + this.version2 + `/party/update`, data, { observe: 'response' });
  }
  updatePartyConfidential(data: any, id: any) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/party/update/confidential`, data, { observe: 'response' });
  }
  partyGet(id: any) {
    let payload = {
      id:+id,
      metadata:true
    }
    return this.http.put(this.accountUrl + this.version2 + `/party/get`,payload, { observe: 'response' });
  }
  kycGet(id: any, userId: number) {
    let payload = {
      partyId:+id,
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/bankparty/byparty`,payload, { observe: 'response' });
  }
  getScheduleTransfer(data: any) {
    return this.http.put<any>(this.accountUrl + this.version2 + `/recurringpayments/getbypartyid`,data, { observe: 'response' });
  }
  rewardGet(partyid: any, bankid: any, accountid: any) {
    let payload = {
      id:+partyid,
      accountId:+accountid,
      bankId:+bankid
    }
    return this.http.put(this.accountUrl + this.version2 + `/party/getcompositedata`,payload, { observe: 'response' });
  }

  getActions(data: any) {
    return this.http.post<any>(this.accountUrl + this.version2 + `/rbac/get/allowedpaths`, data, { observe: 'response' });
  }

  getAllTenants(page: any) {
    const pagination = `filtercolumn=id=ge=0;id=le=50&sort=id&limit=${page.ItemsPerPage}&offset=${page.Offset}`;
    return this.http.get<any>(this.accountUrl + this.version2 + `/tenants?${pagination}`, { observe: 'response' });
  }
  getTenant(id: number) {
    return this.http.get<any>(this.accountUrl + this.version2 + `/tenant/get/${id}`, { observe: 'response' });
  }
  updateTenant(data: any, id: number) {
    return this.http.put(this.accountUrl + this.version2 + `/tenant/update/${id}`, data, { observe: 'response' });
  }

  getAllUsers(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/adminusers/byrole`,data, { observe: 'response' });
  }
  createUser(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/adminuser/add', data, { observe: 'response' });
  }
  adminUpdateUser(data: any, id?: any) {
    data.id = id;
    if(!id) delete data.id;
    return this.http.put(this.accountUrl + this.version2 + `/adminuser/update`, data, { observe: 'response' });
  }
  updateUser(data: any, id: any) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/user/update`, data, { observe: 'response' });
  }
  //resetSecurity

  resetSecurityQuestion(data: any, id: any) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/user/resetsecurityquestions`, data, { observe: 'response' });
  }

  updateRole(data: any, id: any) {
    data.id = id;
    data.extraData = 'all';
    return this.http.put(this.accountUrl + this.version2 + `/adminuser/update/role`, data, { observe: 'response' });
  }
  resendInvitatiton(data: any, id: any) {
    data.userId = id;
    return this.http.put(this.accountUrl + this.version2 + `/adminuser/resend`, data, { observe: 'response' });
  }

  getBanks(pagination: any, id: number) {
    return this.http.get<any>(this.accountUrl + this.version2 + `/banks?filtercolumn=tenant_id==${id}&sort=id:asc&limit=${pagination.ItemsPerPage}&offset=${pagination.Offset}`, { observe: 'response' });
  }
  getBank(id: any) {
    let payload = {
      id:id
    }
    return this.http.put(this.accountUrl + this.version2 + `/bank/get`,payload, { observe: 'response' });
  }
  updateBank(data: any, id: any) {
    data.bankId = id;
    return this.http.put(this.accountUrl + this.version2 + `/bank/update`, data, { observe: 'response' });
  }

  getProgramPolicies(id: number, pagination: any) {

    let payload = {
      queryName:'byBankId',
      values:`I:${id}`,
      sort:'id:asc',
      limit:`${pagination.ItemsPerPage}`,
      offset:`${pagination.Offset}`
    }
   return this.http.put<any>(this.accountUrl + this.version2 + `/programpolicies/query`,payload, { observe: 'response' });
  }
  addProgramPolicy(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/programpolicy/add', data, { observe: 'response' });
  }
  getPolicy(id: number) {
    let payload = {
      id:+id
    }
    return this.http.put(this.accountUrl + this.version2 + `/programpolicy/get`,payload, { observe: 'response' });
  }
  getPolicyJson(id: number) {
    let payload = {
      id:id,
      json:true
    }
    return this.http.put(this.accountUrl + this.version2 + `/programpolicy/get`,payload, { observe: 'response' });
  }
  updateProgramPolicy(data: any, id: number) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/programpolicy/update`, data, { observe: 'response' });
  }
  deleteProgramPolicy(id: number) {

    return this.http.delete(this.accountUrl + this.version2 + `/programpolicy/delete/${id}`, { observe: 'response' });
  }

  getAccounts(pagination: any) {

    return this.http.get<any>(this.accountUrl + this.version2 + `/accounts?filtercolumn=id=ge=0&sort=id&limit=${pagination.ItemsPerPage}&offset=${pagination.Offset}`, { observe: 'response' });
  }
  getAccountControls(id: number) {
    let payload = {
      entityType:'AP',
      entityId:+id
    }

    return this.http.put<any>(this.accountUrl + this.version2 + `/acntparam/query`,payload, { observe: 'response' });
  }
  addAccountControls(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/acntparam/add', data, { observe: 'response' });
  }
  updateAccountControls(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/acntparam/update`, data, { observe: 'response' });
  }
  addSingleTransactionControls(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/txnmax/add', data, { observe: 'response' });
  }
  updateSingleTransactionControls(data: any, id: number) {
    return this.http.put(this.accountUrl + this.version2 + `/txnmax/update/${id}`, data, { observe: 'response' });
  }

  getSingleTransaction(id: number, type: any) {
  let payload = {
    entityType: type,
      entityId: +id
  }
    return this.http.put(this.accountUrl + this.version2 + `/txnmax/query`,payload, { observe: 'response' });
  }
  addSingleTransaction(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/txnmax/add', data, { observe: 'response' });
  }
  updateSingleTransaction(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/txnmax/update`, data, { observe: 'response' });
  }
  deleteSignleTransaction(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/txnmax/delete`, data, { observe: 'response' });
  }

  getDailyTransactionLimits(id: number, type: any) {

    let payload = {
      entityId: id,
      entityType: type,
      limitType: 'D',
      extraDetails: true
    }
    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/query`,payload, { observe: 'response' });
  }
  getWeeklyTransactionLimits(id: number, type: any) {
    let payload = {
      entityId: +id,
      entityType: type,
      limitType: 'W',
      extraDetails: true
    }

    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/getall`,payload, { observe: 'response' });
  }
  getMonthlyTransactionLimits(id: number, type: any) {

    let payload = {
      entityId: +id,
      entityType: type,
      limitType: 'M',
      extraDetails: true
    }
    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/getall`,payload, { observe: 'response' });
  }
  addTransactionLimit(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/txnlimit/add', data, { observe: 'response' });
  }
  updateTransactionLimit(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/update`, data, { observe: 'response' });
  }
  deleteTransactionLimit(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/delete`, data, { observe: 'response' });
  }
  resetControl(data) {
    return this.http.put(this.accountUrl + this.version2 + `/control/reset`, data, { observe: 'response' });
  }

  getCardStatus(id: number) {
    let payload = {
      entityType:'PP',
      entityId:+id
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/inststat/query`,payload, { observe: 'response' });
  }
  addCardStatus(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/inststat/add', data, { observe: 'response' });
  }
  deleteCardStatus(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/inststat/delete`, data, { observe: 'response' });
  }
  deleteControl(control: any, data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/${control}/delete`, data, { observe: 'response' });
  }

  getTransactionValidation(id: number) {
    let payload = {
      entityType:'PP',
      entityId:+id
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/txnxref/query`,payload, { observe: 'response' });
  }
  addTransactionValidation(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/txnxref/add', data, { observe: 'response' });
  }
  deleteTransactionValidation(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/txnxref/delete`, data, { observe: 'response' });
  }

  getAllMerchant(id: number) {
    return this.http.get<any>(this.accountUrl + this.version2 + `/merchant/getall/PP/${id}`, { observe: 'response' });
  }
  addMerchant(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/merchant/add', data, { observe: 'response' });
  }
  deleteMerchantName(id: number) {
    return this.http.delete(this.accountUrl + this.version2 + `/merchant/delete/${id}`, { observe: 'response' });
  }

  getMcc(id: number, type: any) {
    let payload = {
      entityId:+id,
      entityType:type
    }
    return this.http.put(this.accountUrl + this.version2 + `/mertype/query`,payload, { observe: 'response' });
  }
  addMcc(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/mertype/add', data, { observe: 'response' });
  }
  deleteMerchantType(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/mertype/delete`, data, { observe: 'response' });
  }

  getTransactionFee(id: number, pagination: any) {
    let payload = {
      queryName:'byPpId',
      values:`I:${id}`,
      sort:'id',
      limit:`${pagination.ItemsPerPage}`,
      offset:`${pagination.Offset}`
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/fees/query`,payload, { observe: 'response' });
  }
  addTransactionFee(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/fee/add', data, { observe: 'response' });
  }
  updateTransactionFee(data: any, id: any) {
    return this.http.put(this.accountUrl + this.version2 + `/fee/update/${id}`, data, { observe: 'response' });
  }
  deleteTransactionFee(id: number) {
    return this.http.delete(this.accountUrl + this.version2 + `/fee/delete/${id}`, { observe: 'response' });
  }

  getTransactionTolerance(id: number, pagination: any) {

    let payload = {
      queryName:'byPpId',
      values:`I:${id}`,
      sort:'id',
      limit:`${pagination.ItemsPerPage}`,
      offset:`${pagination.Offset}`
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/tolerances/query`,payload, { observe: 'response' });
  }
  addTransactionTolerance(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/tolerance/add', data, { observe: 'response' });
  }
  updateTransactionTolerance(data: any, id: any) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/tolerance/update`, data, { observe: 'response' });
  }
  deleteTransactionTolerance(id: number) {
    return this.http.delete(this.accountUrl + this.version2 + `/tolerance/delete/${id}`, { observe: 'response' });
  }

  verifySSN(ssn: any) {
    return this.http.get<any>(this.bankUrl + this.version2 + `/bank/bankpartybyssn/${ssn}`, { observe: 'response' });
  }
  createAccount(data: any) {
    return this.http.post(this.bankUrl + this.version2 + '/bank/composite/add', data, { observe: 'response' });
  }
  getAllParties() {
    return this.http.get<any>(this.accountUrl + this.version2 + '/parties?sort=id&limit=0&offset=0', { observe: 'response' });
  }
  getAllAccounts(data: any) {

    return this.http.put(this.accountUrl + this.version2 + `/accounts/combined`,data, { observe: 'response' });
  }
  mergeAccount(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/party/account/merge', data, { observe: 'response' });
  }

  customerSearchByFields(data: any, pagination: any) {
    const filters = [];

    const fields = [
        { field: "first_name", value: data?.firstName },
        { field: "middle_name", value: data?.middleName },
        { field: "last_name", value: data?.lastName },
        { field: "ssn4", value: data?.ssn4 },
        { field: "mobile_number", value: data?.mobileNumber },
        { field: "email_id", value: data?.email },
        { field: "dob", value: data?.dob },
        { field: "company_name", value: data?.companyName },
    ];
    fields.forEach(item => {
        if (item.value) {
            filters.push({ field: item.field, value: item.value });
        }
    });
    let payload:any = {
      "searchObject": "party",
      "query": [
          {
              "queryObject": "party",
              "filters": filters
          }
      ],
      
      "limit": pagination.ItemsPerPage,
      "offset": pagination.Offset,
      "sort": "old_id",
      "responseFlags": "PARTY:ACCOUNTS-DATA|ACCOUNT:INSTRUMENTS-DATA"
  }

    // data.limit = (pagination.ItemsPerPage).toString();
    // data.sort = 'id:desc';
    // data.offset = (pagination.Offset).toString();
    return this.http.put<any>(this.accountUrl + this.version2 + `/parties/search`,payload, { observe: 'response' });
  }
  customerSearchByPartyId(data: any, pagination: any) {

    let payload:any = {
      ids:data,
      sort:'id:desc',
      limit:(pagination.ItemsPerPage).toString(),
      offset:(pagination.Offset).toString()
    }

    if(payload.ids.bankId){
      payload.bankId = payload.ids.bankId;
      delete payload.ids.bankId;
    }


    return this.http.put(this.accountUrl + this.version2 + `/parties/bydata`,payload, { observe: 'response' });
  }
  cardNameSearch(query: any) {

    query.sort = 'id:desc';
    query.limit = '0';
    query.offset = '0';
    return this.http.put<any>(this.accountUrl + this.version2 + `/instruments/query`,query, { observe: 'response' });
  }
  cardNumberSearch(cardNumber: any) {
    let payload = {
      instrumentId:cardNumber
    }
    return this.http.put(this.accountUrl + this.version2 + `/instrument/byinstrumentid`,payload, { observe: 'response' });
  }
  cardSearchBySolar(data: any, pagination: any) {
    return this.http.put<any>(this.solrUrl + this.version2 + `/solr/getinstruments?limit=${pagination.ItemsPerPage}&offset=${pagination.Offset}`, data, { observe: 'response' });
  }
  partySearchBySolar(data: any, pagination: any) {
    return this.http.put<any>(this.solrUrl + this.version2 + `/solr/getparties?limit=${pagination.ItemsPerPage}&offset=${pagination.Offset}`, data, { observe: 'response' });
  }
  getSearchTransaction(data: any, params: any) {
    return this.http.put(this.solrUrl + this.version2 + `/solr/gettransactions?${params}`, data, { observe: 'response' });
  }
  getPartyTransaction(data: any,query:string) {
    return this.http.put(this.solrUrl + this.version2 + `/solr/gettransactions${query}`, data, { observe: 'response' });
  }

  transactionSearch(payload:TransactionReqObj){
    return this.http.put<any>(this.accountUrl + this.version2 + `/transactions/search`,payload, { observe: 'response' });
  }


  getTransactionDetail(id: number) {
    let payload = {
      transactionID:+id
    }

    return this.http.put<any>(this.accountUrl + this.version2 + `/transaction/get`,payload, { observe: 'response' });
  }

  getCardList(data: any) {
    return this.http.get<any>(this.accountUrl + this.version2 + '/instruments' + data, { observe: 'response' });
  }
  getMonitorbyCards(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/instruments/monitorby',data, { observe: 'response' });
  }
  getProductCount(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/instruments/get/productcount`,data, { observe: 'response' });
  }
  getMonitorbyAccounts(data: any) {
    return this.http.get(this.accountUrl + this.version2 + '/accounts' + data, { observe: 'response' });
  }
  getRefresh(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/account/syncbank', data, { observe: 'response' });
  }
  blockUnblockCard(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/instrument/chblock`, data, { observe: 'response' });
  }
  cancelCard(id: any,loginpartyid?) {
    let payload = {
      id:+id,
      loginPartyId:+loginpartyid
    }
    return this.http.put(this.accountUrl + this.version2 + `/instrument/close`, payload, { observe: 'response' });
  }

  closeLastCard(id: number) {
    return this.http.put(this.accountUrl + this.version2 + `/account/closelastcard`, { id }, { observe: 'response' });
  }

  undeleteInstrument(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/instrument/update`, data, { observe: 'response' });
  }
  activateCard(data) {
    return this.http.put(this.accountUrl + this.version2 + '/instrument/setpin', data, { observe: 'response' });
  }

  getPendingTransactions(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/pendingtransactions/getbypartyid`,data, { observe: 'response' });
  }

  replaceCard(data:any) {
    return this.http.put(this.accountUrl + this.version2 + `/instrument/replace`, data, { observe: 'response' });
  }
  getSettleTransactions(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/transactions/query', data, { observe: 'response' });
  }
  getRejectTransactions(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/transactions/query', data, { observe: 'response' });
  }
  updateTransaction(data: any, id: number) {
    data.id = id;
    return this.http.put(this.accountUrl + this.version2 + `/transaction/update`, data, { observe: 'response' });
  }
  getCardDetails(id: number,metadataRequired = false) {
    let payload:any = {
      id:+id,
      extraData:'all',
      memetadata:true
    }
    if(metadataRequired) payload.metadataRequired = true;
    return this.http.put(this.accountUrl + this.version2 + `/instrument/get`,payload, { observe: 'response' });
  }
  getConfidentialInfo(id: number, encrypt?: boolean,cvv:boolean = true): Observable<HttpResponse<InstrumentDetails>> {
    let payload = {
      id:+id,
      cvv:cvv
    }
    return this.http.put<InstrumentDetails>(this.accountUrl + this.version2 + `/instrument/${encrypt ? 'encrypt' : 'confidential'}`,payload, { observe: 'response' });
  }
  getFullSSN(id: number) {
    let payload = {
      id:id,
    }
    return this.http.put(this.accountUrl + this.version2 + `/party/ssn/get`,payload, { observe: 'response' });
  }
  getOtherControls(id: number) {
    let payload = {
      entityType:'IN',
      entityId:+id
    }

    return this.http.put<any>(this.accountUrl + this.version2 + `/othercontrol/get`,payload, { observe: 'response' });
  }
  getLimit(limit: any, id: number) {

    let payload = {
      entityId: +id,
      entityType: 'IN',
      limitType: limit,
      extraDetails: true
    }
    return this.http.put(this.accountUrl + this.version2 + `/txnlimit/getall`,payload, { observe: 'response' });
  }
  updateOtherControl(data: any) {
    return this.http.post<any>(this.accountUrl + this.version2 + '/othercontrol/add', data, { observe: 'response' });
  }
  getAllHours(id, entityType) {
    let payload = {
      entityId:+id,
      entityType:entityType
    }
    return this.http.put(this.accountUrl + this.version2 + `/hrsrec/query`,payload, { observe: 'response' });
  }

  //not is use.
  updateHours(id, data) {
    return this.http.put(this.accountUrl + this.version2 + '/hrsrec/update/' + id, data, { observe: 'response' });
  }
  addHours(data) {
    return this.http.post(this.accountUrl + this.version2 + '/hrsrec/add', data, { observe: 'response' });
  }
  deleteHours(id) {
    return this.http.delete(this.accountUrl + this.version2 + `/hrsrec/delete/${id}`, { observe: 'response' });
  }
  getGeoFencing(id, gEtype) {
    let payload = {
      entityId:id,
      entityType:gEtype
    }
    return this.http.put(this.accountUrl + this.version2 + `/geofen/query`,payload, { observe: 'response' });
  }
  updateGeoFencing(data) {
    return this.http.put(this.accountUrl + this.version2 + '/geofen/update', data, { observe: 'response' });
  }
  addGeoFencing(data) {
    return this.http.post(this.accountUrl + this.version2 + '/geofen/add', data, { observe: 'response' });
  }
  getAllInterTravel(id, entityType) {
    let payload = {
      entityId:+id,
      entityType:entityType
    }
    return this.http.put(this.accountUrl + this.version2 + `/trvlintl/query`,payload, { observe: 'response' });
  }
  updateInterTravel(data) {
    return this.http.put(this.accountUrl + this.version2 + '/trvlintl/update', data, { observe: 'response' });
  }
  addInterTravel(data) {
    return this.http.post(this.accountUrl + this.version2 + '/trvlintl/add', data, { observe: 'response' });
  }
  blockUser(id: any) {
    return this.http.put(this.accountUrl + this.version2 + `/account/blockall/${id}`, { observe: 'response' });
  }
  addMemo(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/instrument/update`, data, { observe: 'response' });
  }

  getAllReports(code: any, limit: any, offset: any) {
   let payload = {
    queryName:'reportQuery',
    values:`S:${code}`,

    sort:'id:desc',
    limit:(limit).toString(),
    offset:(offset).toString(),
    internaldata:`true`
   }
    return this.http.put<any>(this.reportUrl + this.version2 + `/report/queryall`,payload, { observe: 'response' });
  }
  getReport(id: number,userId:number) {
    let payload = {
      id:+id,

    }
    return this.http.put<any>(this.reportUrl + this.version2 + `/report/get`,payload, { observe: 'response' });
  }
  getReportLookup(code: any,allBankFlag:boolean) {
    let payload = {
      code:code,
     allBankFlag
    }

    return this.http.put<any>(this.reportUrl + this.version2 + `/report/lookup/get`,payload, { observe: 'response' });
  }
  generateReport(code: any, data: any) {
    data.code = code;
    return this.http.post(this.reportUrl + this.version2 + `/report/generate`, data, { observe: 'response', responseType: 'blob' });
  }

  getAllMessages(data: any,query:string) {

    return this.http.put<any>(this.accountUrl + this.version2 + `/adminmessages${query}`,data, { observe: 'response' });

  }
  createMessage(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/adminmessages/add', data, { observe: 'response' });
  }
  updateMessage(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/adminmessages/update`, data, { observe: 'response' });
  }
  deleteMessage(id: any) {
    let payload = {
      id:id
    }
    return this.http.put(this.accountUrl + this.version2 + `/adminmessages/delete`,payload,{ observe: 'response' });
  }
  getCombindAccounts(data: any) {
    return this.http.put(this.accountUrl + this.version2 + '/accounts/combined', data, { observe: 'response' });
  }

  getStatementsForCard(data:any) {
    return this.http.put(this.accountUrl + this.version2 + `/account/statement/get`, data, { observe: 'response' });
  }
  getDocument(id) {
    let payload = {
      id:+id
    }
    return this.http.put(this.docUrl + this.version2 + '/document/get',payload, { responseType: 'blob' });
  }
  getStatementEndPoint(data: any) {
    return `${this.statementUrl}${data}`;
  }
  getReportEndPoint(data: any) {
    return `${this.reportUrl}${data}`;
  }
  getAllAddressByParty(data:any) {
    return this.http.put(this.accountUrl + this.version2 + `/address`,data, { observe: 'response' });
  }
  addBulkCard(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/account/processcif', data, { observe: 'response' });
  }

  getAllExternalReport(data: any) {
    return this.http.put<any>(this.docUrl + this.version2 + `/externalreports/query`,data, { observe: 'response' });
  }
  uploadExternalReport(data: any) {
    return this.http.post(this.docUrl + this.version2 + '/externalreport/add', data, { observe: 'response' });
  }
  updateReport(formData: any, id: any) {
    formData.append('id',id);
    return this.http.put(this.docUrl + this.version2 + `/externalreport/update`, formData, { observe: 'response' });
  }
  deleteReport(id: any) {
    let payload = {
      id:+id
    }
    return this.http.put(this.docUrl + this.version2 + `/externalreport/delete`, payload,{ observe: 'response' });
  }
  getSubSource(source: any) {
    return this.lookupsByTypeReq({
      recordType:source
    });
  }
  holdAdjustment(data:any) {

    return this.http.put(this.accountUrl + this.version2 + `/transaction/holdadjustments`,data, { observe: 'response' });
  }
  approveAdjustment(id, partyId) {
    let payload = {
      parentTxnId:+id,
      approve:true
    };
    return this.http.post(this.accountUrl + this.version2 + `/transaction/approve`, payload, { observe: 'response' });
  }
  rejectAdjustment(id, partyId) {
    let payload = {
      parentTxnId:+id,
      approve:false
    }
    return this.http.post(this.accountUrl + this.version2 + `/transaction/approve`, payload, { observe: 'response' });
  }
  updateAdjustment(data: any) {

    return this.http.post(this.accountUrl + this.version2 + `/transaction/adjustment`, data, { observe: 'response' });
  }
  getPolicyFlags() {
    return this.http.put(this.accountUrl + this.version2 + '/configuration/get', { observe: 'response' });
  }

  getDebitFromList(partyId) {
    let payload = {
      partyId:partyId
    }
    return this.http.put(this.accountUrl + this.version2 + `/instrument/debitinstrumentsbypartyid`,payload, { observe: 'response' });
  }
  getCreditFromList(partyId) {
    let payload = {
      partyId:partyId
    }
    return this.http.put(this.accountUrl + this.version2 + `/instrument/creditinstrumentsbypartyid`, payload,{ observe: 'response' });
  }
  getTransferFromList(data: any) {
    return this.http.get(this.accountUrl + this.version2 + data, { observe: 'response' });
  }
  getScheduledDetail(data: any) {
    return this.http.get(this.accountUrl + this.version2 + `/recurringpayments${data}`, { observe: 'response' });
  }
  loadMoneyForBankAdjustment(data: any) {

    return this.http.post(this.accountUrl + this.version2 + '/transaction/transfer', data, { observe: 'response' });
  }
  loadMoney(data: any) {
    return this.http.post(this.accountUrl + this.version2 + '/transaction/transfer', data, { observe: 'response' });
  }
  schedulePayment(data: any) {
    return this.http.post(this.accountUrl + this.version2 + `/recurringpayment/addorupdate`, data, { observe: 'response' });
  }
  deleteSchedulePayment(reId) {
    return this.http.delete(this.accountUrl + this.version2 + `/recurringpayment/delete/${reId}`, { observe: 'response' });
  }
  updateSchedulePayment(reId, data) {
    data.id = reId;
    return this.http.put(this.accountUrl + this.version2 + `/recurringpayment/update`, data, { observe: 'response' });
  }
  getBankTransactions(data: any, pagination?: any) {
    return this.http.post(this.bankUrl + this.version2 + '/bank/transactions', data, { observe: 'response' });
  }

  getAllDocs(data: any) {
    return this.http.put<any>(this.docUrl + this.version2 + '/document/query', data, { observe: 'response' });
  }
  uploadDocument(data: any) {
    return this.http.post(this.docUrl + this.version2 + '/document/upload', data, { observe: 'response' });
  }
  downloadTransactionReport(data) {
    return this.http.post(this.reportUrl + this.version2 + '/report/generate', data, { observe: 'response', responseType: 'blob' });
  }
  uploadProfilePic(formData: any) {
    return this.http.post(this.docUrl + this.version2 + '/document/addorupdate', formData, { observe: 'response' });
  }
  updateDocImg(formData: any, id: number) {
    formData.append('id',id);
    return this.http.put(this.docUrl + this.version2 + `/document/update`, formData, { observe: 'response' });
  }
  deleteDoc(id: any) {
    return this.http.put(this.docUrl + this.version2 + `/document/delete`,{id}, { observe: 'response' });
  }

  // Lookups
  lookupsByTypeReq(data:any){

    return this.http.put(this.accountUrl + this.version2 + '/lookups/bytype',data, { observe: 'response' }).pipe(shareReplay());
  }
  getMerchantName() {
    return this.lookupsByTypeReq({
      recordType:'MERCNAME'
    });
  }
  getMerchantType() {
    return this.lookupsByTypeReq({
      recordType:'MCC.TYPE'
    });
  }
  getAction() {
    return this.lookupsByTypeReq({
      recordType:'CNTL.ACT'
    });
  }
  getSendAlert() {
    return this.lookupsByTypeReq({
      recordType:'ALE.ACT'
    });
  }
  getInitialPin() {
    return this.lookupsByTypeReq({
      recordType:'INIT.PIN'
    });
  }
  getPeriod() {
    return this.lookupsByTypeReq({
      recordType:'PERIOD'
    });
  }
  getAllowed() {
    return this.lookupsByTypeReq({
      recordType:'ALLOW'
    });

  }
  getAmountType() {
    return this.lookupsByTypeReq({
      recordType:'TOL.AMTT'
    });
  }
  getReportMenus() {
    return this.lookupsByTypeReq({
      recordType:'RPOTGRP'
    });
  }
  getCards() {
    return this.lookupsByTypeReq({
      recordType:'CARDTYPE'
    });

  }
  getTimingList() {
    return this.lookupsByTypeReq({
      recordType:'TIME'
    });
  }
  getTimeSlotList() {
    return this.lookupsByTypeReq({
      recordType:'TIMESLOT'
    });
  }
  getPrintCardStatus() {
    return this.lookupsByTypeReq({
      "recordType":"PRINT"
    });
  }
  getWeekdayList() {
    return this.lookupsByTypeReq({
      recordType:'WEEKDAY',
    });
  }
  getCountriesList() {
    return this.lookupsByTypeReq({
      recordType:'COUNTRY',
      flags:'ALL'
    });
  }
  getRoles() {
    let payload = {

      recordType:'ADMINROL'
    }
    return this.http.put(this.accountUrl + this.version2 + `/lookup/byrole`,payload, { observe: 'response' });
  }
  getSecurityQuestions() {
    return this.lookupsByTypeReq({
      recordType:'SECQTN',
   });
  }
  getApps() {
    return this.lookupsByTypeReq({
      recordType:'APP',
   });
  }
  getAppTypes() {
    return this.lookupsByTypeReq({
      recordType:'APPTYPE',
   });
  }
  getSources() {
    return this.lookupsByTypeReq({
      recordType:'ERSOURCE',
      flags:'S:numcode'
   });
  }
  getEventActions() {
    return this.lookupsByTypeReq({
      recordType:'ADMEVACT',
  });
  }
  getCardsStatus() {
    return this.lookupsByTypeReq({
      recordType:'CARDSTAT',
  });
  }

  getPartyStatus() {
    return this.lookupsByTypeReq({
      recordType:'PRTYSTAT',
  });
  }

  getKYCStatus() {
    return this.lookupsByTypeReq({
      recordType:'KYCSTAT',
  });
  }
  getOFACStatus() {
    return this.lookupsByTypeReq({
      recordType:'OFACSTAT',
  });
  }
  getTransactionStatus() {
    return this.lookupsByTypeReq({
      recordType:'TXNSTAT',
  });
  }
  getEntityType() {
    return this.lookupsByTypeReq({
      recordType:'ENTTYPE',
  });

  }
  getSuffix() {
    return this.lookupsByTypeReq({
      recordType:'Suffix',
  });

  }
  getTitles() {
    return this.lookupsByTypeReq({
      recordType:'Title',
  });

  }
  getBusinessType() {
    let payload = {

      recordType:'BUSNTYPE'
    }
    return this.http.put(this.accountUrl + this.version2 + `/lookup/byrole`,payload, { observe: 'response' });
  }
  getFrequencyLookup() {
    return this.lookupsByTypeReq({
      recordType:'FREQUNCY',
  });
  }
  getDocMetadataLookup() {
    return this.lookupsByTypeReq({
      recordType:'DOCMETA',
  });
  }
  getAllBanks() {
    let payload = {
      queryName:'allBanks',
      sort:'id',
      limit:'0',
      offset:'0',

    }
    return this.http.put(this.accountUrl + this.version2 + '/banks/query',payload, { observe: 'response' });
  }
  getAdjustmentBanks(id: number) {
    let payload = {
      id:id
    }
    return this.http.put<any>(this.accountUrl + this.version2 + `/bank/accounts`,payload, { observe: 'response' });
  }
  getBlockFlags() {
    return this.lookupsByTypeReq({
      recordType:'BLOKFLAG',
  });
  }
  getShipTypeLookup() {
    return this.lookupsByTypeReq({
      recordType:'SHIPTYPE',
  });
  }

  // Getting from storage
  getPartyId() {
    return this.auth.getAppMode().getItem('partyId');
  }
  getPartyName() {
    return this.auth.getAppMode().getItem('name');
  }
  getBankId() {
    return this.auth.getAppMode().getItem('bankId');
  }
  getUserName() {
    return this.auth.getAppMode().getItem('username');
  }
  getUserId() {
    return this.auth.getAppMode().getItem('userId');
  }
  getPartyType() {
    return this.auth.decrypt(this.auth.getAppMode().getItem('partyType'));
  }
  getSelectedBankId() {
    return this.auth.getAppMode().getItem('selectedBankId');
  }
  getBankBalance(data){
    return this.http.put(this.bankUrl + this.version2 + '/bank/account/balance', data, { observe: 'response' });
  }
  //bank transaction component service
  searchBankTransaction(data){
    return this.http.post(this.bankUrl + this.version2 + '/bank/transactions', data, { observe: 'response' });
  }
// download Bank Transaction
  downloadBankTransaction(data){
    data.code = 'bank_transactions';
    return this.http.post(this.reportUrl + this.version2 + '/report/generate', data, { observe: 'response', responseType: 'blob' });
  }

  //Get Fraud INfoLIst
  getFraudInfoList(cardId) {
    let instrumentId = +cardId;
    return this.http.put(this.accountUrl + this.version2 + `/fraudinfo/get`,{instrumentId}, { observe: 'response' });
  }

  getInnerBankList(){
    let payload = {
     // loginPartyId:+this.getPartyId()
    }
    return this.http.put(`${this.accountUrl}${this.version2}/banks/getall/enabled`,payload,{observe:'response'}).pipe(
      shareReplay(),
    );
  }

  searchByBankAccount(requestObject: InnerBankListCustomerSearchRequest): Observable<any> {

    let payload = {
      bankAccountNumber:requestObject.bankAccountNumber
    }
    return this.http.put(`${this.accountUrl}${this.version2}/parties/get`,payload,{observe:'response'}).pipe(
      shareReplay(),
    );
  }


  getState() {
    return this.lookupsByTypeReq({
      recordType:'state',
  });
  }

  getReasonCode(){
    return this.lookupsByTypeReq({
      recordType:'RESCODE',
  });
  }

  getFraudStatus(){
    return this.lookupsByTypeReq({
      recordType:'FRUDSTAT'
    })
  }

  getTagList() {
    return this.http.get(this.glapiUrl + this.version2 + '/glaccount/gettags', {observe: 'response' });


  }

  pendingActivity(payload:any){
    return this.http.put(this.accountUrl + this.version2 + '/pendingactivity/query', payload, { observe: 'response' });
  }

  scheduleActivity(payload:any){
    return this.http.put(this.accountUrl + this.version2 + '/pendingactivity/search', payload, { observe: 'response' });
  }
  scheduleStatusUpdate(payload:any){
    return this.http.put(this.accountUrl + this.version2 + '/pendingactivity/update', payload, { observe: 'response' });
  }
  printCardStatusUpdate(payload:any){
    return this.http.put(this.accountUrl + this.version2 + '/instrument/update', payload, { observe: 'response' });
  }

  rejectOnboardingApplocation(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/adminuser/rejectcard', data, { observe: 'response' });
  }

  approveOnboardingApplocation(data:any){
    return this.http.post(this.accountUrl + this.version2 + '/adminuser/approvecard', data, { observe: 'response' });
  }

  pendingActivityByPartyId(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/pendingactivity/getbypartyid', data, { observe: 'response' });
  }
  printCardData(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/instruments/printableinstrument', data, { observe: 'response' });
  }


  getBinReportLookup(code: any, id: number) {
    let payload = {
      code:code,
      bankId:+id
    }
    return this.http.put<any>(this.reportUrl + this.version2 + `/report/lookup/get`,payload, { observe: 'response' });
  }

  getStatementUrl(url:string){
    return this.getReportEndPoint(this.version2+url);
    }

  /**
   * External Bank Accounts Listing API
   * @param {ExternalBankAccountListingRequest} reqObj
   * @returns {Observable<ExternalBankAccountListing>}
   */
  getExternalBankAccounts(
    reqObj: ExternalBankAccountListingRequest
  ): Observable<ExternalBankAccountListing> {
    return this.http
      .put<ExternalBankAccountListing>(
        `${this.accountUrl}${this.version2}/achsources/query`,
        reqObj
      )
      .pipe(
        tap((res) => {
          if (res && res.data) {
            res.data.forEach((element, index) => {
              if (res.data[index].metadata && res.data[index].metadata?.length) {
                res.data[index].metadata = JSON.parse(element.metadata);
              }
            });
            return res;
          }
        })
      );
  }


  getOTP(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/user/sendotp', data, { observe: 'response' });
  }

  getFinancialInfo(data: any) {
    return this.http.put(this.accountUrl + this.version2 + `/partycredit/get`, data, { observe: 'response' });
  }

  documentFetch(reqObj: DocumentFetchRequest) {
    return this.http.put(
      this.docUrl + this.version2 + `/document/fetch`,
      reqObj,
      { responseType: "blob" }
    );
  }

  getBankLogo(bankId) {
    let reqObj: DocumentFetchRequest = {
      businessType: "banklogo",
      entityType: "ba",
      entityId: parseInt(bankId),
    };
    return this.documentFetch(reqObj);
  }

  executeAdminQuery(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/adminuser/executeadminquery', data, { responseType:'text' });
  }

  scriptQuery(data:any){
    return this.http.put(this.accountUrl + this.version2 + '/script/execute', data, { observe:'response' });
  }

}
