/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { DataService } from 'src/app/services/data.service';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('menus') menus = {} as any;
  reportMenus: any;

  menuKeys = {} as any;
  menuValues = {} as any;
  newReportMenu: any;

  name: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private customeSnakbar:CustomSnakbarComponent,
    private dataservice: DataService
  ) { }

  ngOnInit() {
    this.name = this.service.getPartyName();
    this.GetReportMenus();
  }


  GetReportMenus() {
    this.spinner.show();
    this.service.getReportMenus().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.reportMenus = res.body.data;
        this.dataservice.setReportMenus(this.reportMenus);
      }
    });
  }

  onLogout() {
    let postData = {
    };

    this.spinner.show();
    this.service.userLogout(postData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Logout Successfully', '', 'success');
        this.auth.logout();
        this.router.navigate(['/login']);
      }
    });
  }
}
