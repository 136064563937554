/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';


@Component({
  selector: "app-activate-card-dialog",
  templateUrl: "./activate-card-dialog.component.html",
  styleUrls: ["./activate-card-dialog.component.scss"],
})
export class ActivateCardDialogComponent implements OnInit {
  submitted = false;
  activateCardForm: UntypedFormGroup;
  cardId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ActivateCardDialogComponent>,
    private fb: UntypedFormBuilder,
    private service: RestServicesService,
    private spinner: NgxSpinnerService,
    private customeSnakbar: CustomSnakbarComponent
  ) {
    this.activateCardForm = this.fb.group({
      pin: [null, [Validators.required, CustomValidators.alphanumeric]],
    });
  }

  ngOnInit() {
    if (this.data) {
      this.cardId = this.data.cardId;
    }
  }
  onActivateCardFormSubmit() {
    this.submitted = true;
    if (this.activateCardForm.invalid) {
      return;
    } else {
      const finalPayload = this.createFinalPaylaod();
      this.activateCard(finalPayload);
    }
  }
  createFinalPaylaod() {
    const postData: any = {};

    postData.id = this.cardId;
    postData.action = "A";
    postData.pin = this.activateCardForm.value.pin;

    return postData;
  }

  activateCard(payload) {
    this.spinner.show();
    this.service.activateCard(payload).subscribe({
      next: (resp: any) => {
        this.spinner.hide();
        if (resp.body) {
          this.customeSnakbar.openCustomSnakbar(
            "Card Activated Succesfully!",
            "",
            "success"
          );
          this.dialogRef.close(resp.body);
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.customeSnakbar.openCustomSnakbar(
          error.error.detail,
          "",
          "success"
        );
      },
    });
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
}
