<div class="flex flex-col gap-4">
  <mat-card class="mat-elevation-z8 mainCard">
    <mat-card-title>
      <div class="sectionScript flex flex-row justify-between items-center">
        <span class="mat-card-title flex items-center">
          <a class="cursor-pointer mr-1.5" (click)="$event.preventDefault();executeInitialQuery()">
            <mat-icon class="!text-[26px] !text-[#2274a4]">home</mat-icon>
          </a> | Run Script</span>
      </div>
    </mat-card-title>
    <div class="terminal_container">
      <div class="inner select">
        <!-- SELECT SECTION -->
        <ng-container [ngTemplateOutlet]="script" [ngTemplateOutletContext]="{'value':'SELECT'}"></ng-container>
      </div>

      <div class="inner">
        <!-- FORM SECTION -->
        <ng-container *ngFor="let response of response;index as i;trackBy:trackByFn" >
          <ng-template [ngIf]="response.section === 'FORM'">
            <div aria-describedby="form table" border="1" class="table_border">
              <ng-container *ngFor="let itemDat of response.data;index as e;trackBy:trackByDt">
                <ng-template [ngIf]="!checkTab(itemDat) && !checkBackTick(itemDat)">
                  <p class="mb-2">{{itemDat?.replace(backTickRegex, '')}}</p>
                </ng-template>
                <div class="my-5 w-full" *ngIf="itemDat === '`form`'">


                  <!-- Remove the template injection from for loop and directly inject the form  -->

                  <form *ngIf="response.form" [formGroup]="dynamicFormGroup" (ngSubmit)="submit($event)">
                    <div class="grid grid-cols-2 !gap-2 items-center">
                      <ng-template ngFor [ngForOf]="response.form.parameters" let-item>
                        <div *ngIf="(item.fieldType | lowercase) === 'string'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <input class="pl-3" type="text" matInput placeholder="{{ item.label }}" formControlName="{{item.field}}" />
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase) === 'number'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <input class="pl-3" type="number" matInput placeholder="{{ item.label }}"
                              formControlName="{{item.field}}" />
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                          <mat-error
                            *ngIf="submitted && dynamicFormGroup.controls[item.field].touched && dynamicFormGroup.hasError('maxlength', [item.field])"
                            class="invalid-feedback">
                            Maximum length error</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase) === 'email'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <input class="pl-3" type="email" matInput placeholder="{{ item.label }}" formControlName="{{item.field}}" />
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase)=== 'date'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <input matInput [max]="maxDate" [maxlength]="10" (keyup)="commonUtilityService.keyPress($event)"
                              [matDatepicker]="dynamicName" formControlName="{{item.field}}" />
                            <mat-datepicker-toggle matSuffix [for]="dynamicName"></mat-datepicker-toggle>
                            <mat-datepicker #dynamicName></mat-datepicker>
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase)=== 'amount'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <input class="pl-3" type="number" matInput placeholder="{{ item.label }}"
                              formControlName="{{item.field}}" />
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                          <mat-error
                            *ngIf="submitted && dynamicFormGroup.controls[item.field].touched && dynamicFormGroup.hasError('maxlength', [item.field])"
                            class="invalid-feedback">
                            Maximum length error</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase)=== 'select'" class="fld_width col-span-1">
                          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
                            <mat-label>{{ item.label }}</mat-label>
                            <mat-select formControlName="{{item.field}}" class="select_fld">
                              <ng-template [ngIf]="item?.dropdown.length" [ngIfElse]="blankDropDown">
                                <ng-template ngFor [ngForOf]="(item.dropdown).split('|')" let-option>
                                  <mat-option [value]="option.split('=')[0]">{{option.split('=')[1]}}</mat-option>
                                </ng-template>
                              </ng-template>
                              <ng-template #blankDropDown>
                                <mat-option [value]="''">No value</mat-option>
                              </ng-template>
                            </mat-select>
                          </mat-form-field>
                          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
                            class="invalid-feedback">
                            {{item.label}} is required</mat-error>
                        </div>

                        <div *ngIf="(item.fieldType | lowercase) === 'hidden'" class="fld_width col-span-1">
                          <input class="pl-3" type="hidden" placeholder="{{ item.label }}" formControlName="{{item.field}}" />
                        </div>
                        <!-- copy field type as hidden -->
                        <div *ngIf="(item.fieldType | lowercase) === 'COPY'" class="fld_width col-span-1">
                          <input class="pl-3" type="hidden" placeholder="{{ item.label }}" formControlName="{{item.field}}" />
                        </div>
                      </ng-template>

                      <div class="flex col-span-2 col-start-1 gap-2">
                        <button   *ngIf="response.form.cancelButton" [value]="response.form.cancelCommand" class="submit_button"  type="submit"
                          color="primary">{{response.form.cancelButton}}</button>
                        <button #sbmtButton   *ngIf="response.form.submitButton" [value]="response.form.submitCommand" class="submit_button" type="submit"
                          color="primary">{{response.form.submitButton}}</button>
                      </div>
                    </div>
                  </form>



                </div>

                <ng-template [ngIf]="checkTab(itemDat) && !checkBackTick(itemDat)">
                  <div class="table_row">
                    <ng-template ngFor [ngForOf]="itemDat.split('\t')" let-tbSplit>
                      <div class="table_col">{{tbSplit}}</div>
                    </ng-template>
                  </div>
                </ng-template>

                <ng-template [ngIf]="!checkTab(itemDat) && checkBackTick(itemDat)">

                  <div class="table_row" *ngIf="itemDat !== '`form`'">
                    <ng-template ngFor [ngForOf]="itemDat.split('\t')" let-tSplitedData>

                      <ng-template [ngIf]="checkBackTick(tSplitedData)" [ngIfElse]="noBackTick">
                        <ng-template [ngIf]="tSplitedData?.includes('link')" [ngIfElse]="noLink">
                          <span class="table_col">
                            <a class="script_button"
                              [routerLink]="tSplitedData.split('|')[2]?.replace(backTickRegex, '')">{{tSplitedData.split('|')[1]}}</a>
                          </span>
                        </ng-template>
                        <ng-template #noLink>
                          <ng-template [ngIf]="tSplitedData.includes('_select')" [ngIfElse]="otherBtn">
                            <button  [matMenuTriggerFor]="menu" class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                            <mat-menu #menu="matMenu">
                              <button type="button" mat-menu-item
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                            [matTooltipPosition]="'right'"
                            class="">select</button>
                            <button type="button" mat-menu-item
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, '').replace(selectRegex,'_copy'),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="'Copy this object to copy buffer'"
                            [matTooltipPosition]="'right'"
                            class="">copy</button>
                            </mat-menu>
                          </ng-template>
                          <ng-template #otherBtn>
                            <button type="button"
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                            class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                          </ng-template>

                        </ng-template>
                      </ng-template>
                      <ng-template #noBackTick>
                        <div table_col>{{tSplitedData}}</div>
                      </ng-template>
                    </ng-template>
                  </div>
                </ng-template>

                <ng-template [ngIf]="checkTab(itemDat) && checkBackTick(itemDat)">

                  <div class="table_row">
                    <ng-template ngFor [ngForOf]="itemDat.split('\t')" let-tSplitedData>

                      <ng-template [ngIf]="checkBackTick(tSplitedData)" [ngIfElse]="noBackTick">
                        <ng-template [ngIf]="tSplitedData?.includes('link')" [ngIfElse]="noLink">
                          <span class="table_col">
                            <a class="script_button"
                              [routerLink]="tSplitedData.split('|')[2]?.replace(backTickRegex, '')">{{tSplitedData.split('|')[1]}}</a>
                          </span>
                        </ng-template>
                        <ng-template #noLink>
                          <ng-template [ngIf]="tSplitedData.includes('_select')" [ngIfElse]="otherBtn">
                            <button  [matMenuTriggerFor]="menu" class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                            <mat-menu #menu="matMenu">
                              <button type="button" mat-menu-item
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                            [matTooltipPosition]="'right'"
                            class="">select</button>
                            <button type="button" mat-menu-item
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, '').replace(selectRegex,'_copy'),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="'Copy this object to copy buffer'"
                            [matTooltipPosition]="'right'"
                            class="">copy</button>
                            </mat-menu>
                          </ng-template>
                          <ng-template #otherBtn>
                            <button type="button"
                            (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),response.section,data,tSplitedData.split('|')[2])?.replace(backTickRegex, '')"
                            [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                            class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                          </ng-template>

                        </ng-template>
                      </ng-template>
                      <ng-template #noBackTick>
                        <div table_col>{{tSplitedData}}</div>
                      </ng-template>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-container>
            </div>
            <ng-template [ngIf]="!response?.data.includes('`form`') && response?.form?.parameters.length > 0">
              <ng-container [ngTemplateOutlet]="formTemplate"
                [ngTemplateOutletContext]="{'value':response}"></ng-container>
            </ng-template>
          </ng-template>

        </ng-container>

      </div>

      <div class="inner">
        <div class="flex flex-row justify-end mb-2">
          <!-- COPY SECTION -->
          <span *ngIf="checkCopySection()"><a (click)="$event.preventDefault();clearCopySection()"
              class="text-white font-semibold cursor-pointer text-xs script_button">Clear</a></span>
        </div>
        <ng-container [ngTemplateOutlet]="script" [ngTemplateOutletContext]="{'value':'COPY'}"></ng-container>
      </div>

      <div class="inner" #DataSection>
        <!-- DATA SECTION -->

        <ng-container [ngTemplateOutlet]="script" [ngTemplateOutletContext]="{'value':'DATA'}"></ng-container>
      </div>
    </div>
  </mat-card>
</div>
<!-- ################### SCRIPT SECTION ############### -->
<ng-template let-value="value" #script>
  <div aria-describedby="Scripting query table" border="1" class="table_border">
    <ng-template ngFor [ngForOf]="response" let-sections>
      <ng-template [ngIf]="sections.section ===value">

        <div class="flex flex-row justify-end" *ngIf="value === 'DATA'"> <mat-icon
            [cdkCopyToClipboard]="copyToClipBoard(sections.data)"
            (cdkCopyToClipboardCopied)="customeSnakbar.openCustomSnakbar('Data Copied to Clipboard', '', 'success')"
            class="text-[#2274a4] !text-[18px] cursor-pointer material-icons-outlined">file_copy</mat-icon> </div>
        <ng-template ngFor [ngForOf]="sections.data" let-data let-i="index">
          <ng-template [ngIf]="!checkTab(data) && !checkAtRate(data) && !checkBackTick(data)">
            <h3 class="text-black mt-1 mb-2 w-full">{{data}}</h3>
          </ng-template>
          <ng-template [ngIf]="checkAtRate(data)">
            <br>
          </ng-template>

          <ng-template [ngIf]="!checkTab(data) && checkBackTick(data) && !checkAtRate(data)">
            <br>
            <ng-template [ngIf]="data.includes('_select')" [ngIfElse]="otherBtn">
              <button  [matMenuTriggerFor]="menu" class="script_button">{{data.split('|')[2]?.replace(backTickRegex, '')}}</button>
              <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item
              (click)="callApi((data.split('|')[0])?.replace(backTickRegex, ''),data.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,data.split('|')[2])?.replace(backTickRegex, '')"
              [matTooltip]="data.split('|')[3]?.replace(backTickRegex, '')"
              [matTooltipPosition]="'right'"
              class="">select</button>
              <button type="button" mat-menu-item
              (click)="callApi((data.split('|')[0])?.replace(backTickRegex, '').replace(selectRegex,'_copy'),data.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,data.split('|')[2])?.replace(backTickRegex, '')"
              [matTooltip]="'Copy this object to copy buffer'"
              [matTooltipPosition]="'right'"
              class="">copy</button>
              </mat-menu>
            </ng-template>
            <ng-template #otherBtn>
              <!-- String outside the backtick -->
              <ng-template [ngIf]="data?.replace(getSubstringInBackticks(data),'')" let-withoutBackTick>
                {{withoutBackTick.replace(backTickRegex,' ')}}
              </ng-template>
              <!-- String Inside the backtick -->
              <ng-template [ngIf]="getSubstringInBackticks(data)" let-backTickData>
                <button type="button"
                (click)="callApi((backTickData.split('|')[0])?.replace(backTickRegex, ''),backTickData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,backTickData.split('|')[2])?.replace(backTickRegex, '')"
                [matTooltip]="backTickData.split('|')[3]?.replace(backTickRegex, '')"
                class="script_button mt-0.5 mb-0.5">{{backTickData.split('|')[2]?.replace(backTickRegex, '')}}</button>
              </ng-template>

            </ng-template>
          </ng-template>

          <ng-template [ngIf]="checkTab(data) && !checkBackTick(data) && !checkAtRate(data)">
            <div class="table_row">
              <ng-template ngFor [ngForOf]="data.split('\t')" let-headerPart>
                <div class="table_col">{{headerPart}}</div>
              </ng-template>
            </div>
          </ng-template>

          <ng-template [ngIf]="checkTab(data) && checkBackTick(data)">
            <div class="table_row">
              <ng-template ngFor [ngForOf]="data.split('\t')" let-tSplitedData>
                <ng-template [ngIf]="checkBackTick(tSplitedData)" [ngIfElse]="noBackTick">
                  <ng-template [ngIf]="tSplitedData.includes('_select')" [ngIfElse]="defaultBtn">
                    <div class="table_col">
                      <ng-template [ngIf]="value === 'COPY'" [ngIfElse]="otherSection">
                        <button  [matMenuTriggerFor]="menu" class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                        <mat-menu #menu="matMenu">
                          <button type="button" mat-menu-item
                        (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,tSplitedData.split('|')[2]?.replace(backTickRegex, ''))"
                        [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                        [matTooltipPosition]="'right'"
                        class="">select</button>
                        <button type="button" mat-menu-item
                        (click)="callApi('',tSplitedData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,'remove')"
                        [matTooltip]="'Remove this object from buffer'"
                        [matTooltipPosition]="'right'"
                        class="">remove</button>
                        </mat-menu>
                      </ng-template>
                      <ng-template #otherSection>
                        <button  [matMenuTriggerFor]="menu" [matMenuTriggerFor]="menu"  class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button>
                        <mat-menu #menu="matMenu">
                          <button type="button" mat-menu-item
                        (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,tSplitedData.split('|')[2]?.replace(backTickRegex, ''))"
                        [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                        [matTooltipPosition]="'right'"
                        class="">select</button>
                        <button type="button" mat-menu-item
                        (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, '').replace(selectRegex,'_copy'),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,tSplitedData.split('|')[2]?.replace(backTickRegex, ''))"
                        [matTooltip]="'Copy this object to copy buffer'"
                        [matTooltipPosition]="'right'"
                        class="">copy</button>
                        </mat-menu>
                      </ng-template>

                    </div>
                  </ng-template>
                  <ng-template #defaultBtn>
                    <div class="table_col"><button type="button"
                      (click)="callApi((tSplitedData.split('|')[0])?.replace(backTickRegex, ''),tSplitedData.split('|')[1]?.replace(backTickRegex, ''),sections.section,data,tSplitedData.split('|')[2]?.replace(backTickRegex, ''))"
                      [matTooltip]="tSplitedData.split('|')[3]?.replace(backTickRegex, '')"
                      class="script_button">{{tSplitedData.split('|')[2]?.replace(backTickRegex, '')}}</button></div>
                  </ng-template>

                </ng-template>
                <ng-template #noBackTick>
                  <div class="table_col">{{tSplitedData}}</div>
                </ng-template>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
</ng-template>


<!-- ###################form template############### -->


<ng-template let-value="value" #formTemplate>
  <form *ngIf="value.form" [formGroup]="dynamicFormGroup" (ngSubmit)="submit($event)">
    <div class="grid grid-cols-2 !gap-2 items-center">
      <ng-template ngFor [ngForOf]="value.form.parameters" let-item>
        <div *ngIf="(item.fieldType | lowercase) === 'string'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <input class="pl-3" type="text" matInput placeholder="{{ item.label }}" formControlName="{{item.field}}" />
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase) === 'number'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <input class="pl-3" type="number" matInput placeholder="{{ item.label }}"
              formControlName="{{item.field}}" />
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
          <mat-error
            *ngIf="submitted && dynamicFormGroup.controls[item.field].touched && dynamicFormGroup.hasError('maxlength', [item.field])"
            class="invalid-feedback">
            Maximum length error</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase) === 'email'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <input class="pl-3" type="email" matInput placeholder="{{ item.label }}" formControlName="{{item.field}}" />
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase)=== 'date'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <input matInput [max]="maxDate" [maxlength]="10" (keyup)="commonUtilityService.keyPress($event)"
              [matDatepicker]="dynamicName" formControlName="{{item.field}}" />
            <mat-datepicker-toggle matSuffix [for]="dynamicName"></mat-datepicker-toggle>
            <mat-datepicker #dynamicName></mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase)=== 'amount'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <input class="pl-3" type="number" matInput placeholder="{{ item.label }}"
              formControlName="{{item.field}}" />
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
          <mat-error
            *ngIf="submitted && dynamicFormGroup.controls[item.field].touched && dynamicFormGroup.hasError('maxlength', [item.field])"
            class="invalid-feedback">
            Maximum length error</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase)=== 'select'" class="fld_width col-span-1">
          <mat-form-field class="script_inp_fld" appearance="outline" color="white" floatLabel="auto">
            <mat-label>{{ item.label }}</mat-label>
            <mat-select formControlName="{{item.field}}" class="select_fld">
              <ng-template [ngIf]="item?.dropdown.length" [ngIfElse]="blankDropDown">
                <ng-template ngFor [ngForOf]="(item.dropdown).split('|')" let-option>
                  <mat-option [value]="option.split('=')[0]">{{option.split('=')[1]}}</mat-option>
                </ng-template>
              </ng-template>
              <ng-template #blankDropDown>
                <mat-option [value]="''">No value</mat-option>
              </ng-template>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="item.required===true && submitted && dynamicFormGroup.hasError('required', [item.field])"
            class="invalid-feedback">
            {{item.label}} is required</mat-error>
        </div>

        <div *ngIf="(item.fieldType | lowercase) === 'hidden'" class="fld_width col-span-1">
          <input class="pl-3" type="hidden" placeholder="{{ item.label }}" formControlName="{{item.field}}" />
        </div>
        <!-- copy field type as hidden -->
        <div *ngIf="(item.fieldType | lowercase) === 'COPY'" class="fld_width col-span-1">
          <input class="pl-3" type="hidden" placeholder="{{ item.label }}" formControlName="{{item.field}}" />
        </div>
      </ng-template>

      <div class="flex col-span-2 col-start-1 gap-2">
        <button   *ngIf="value.form.cancelButton" [value]="value.form.cancelCommand" class="submit_button"  type="submit"
          color="primary">{{value.form.cancelButton}}</button>
        <button #sbmtButton   *ngIf="value.form.submitButton" [value]="value.form.submitCommand" class="submit_button" type="submit"
          color="primary">{{value.form.submitButton}}</button>
      </div>
    </div>
  </form>
</ng-template>
