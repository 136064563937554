/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.scss']
})
export class VerifyEmailDialogComponent {

  submitted = false;
  verifyForm: UntypedFormGroup;
  emailToCompare = '';
  dobToCompare: any;
  tinToCompare = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<VerifyEmailDialogComponent>,
    private fb: UntypedFormBuilder,
    public customeSnakbar:CustomSnakbarComponent
  ) {
    this.verifyForm = this.fb.group({
      email: [null, [Validators.required, CustomValidators.customEmail]],
    });

    if (data) {
      this.emailToCompare = this.data.email.trim();
    }
  }


  onFormSubmit() {
    this.submitted = true;

    if (this.verifyForm.invalid) {
      return;
    }

    if (this.emailToCompare.toLowerCase() !== this.verifyForm.value.email.toLowerCase().trim()) {
      this.customeSnakbar.openCustomSnakbar('Email does not match', '', 'error');
      return;
    }
    this.dialogRef.close(true);
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
  NotAllowSpace(event) {
    let k;
    k = event.charCode;
    return (k !== 32);
  }
}
