/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';

@Component({
  selector: "app-replace-card-dialog",
  templateUrl: "./replace-card-dialog.component.html",
  styleUrls: ["./replace-card-dialog.component.scss"],
})
export class ReplaceCardDialogComponent implements OnInit {
  submitted = false;
  replaceCardForm: UntypedFormGroup;
  profileData: any = {};
  isSameAsHomeAddress = false;
  cardId: any;
  csId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ReplaceCardDialogComponent>,
    private fb: UntypedFormBuilder,
    public customeSnakbar: CustomSnakbarComponent,
    private service: RestServicesService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.createReplaceCardForm();
    if (data) {
      this.cardId = this.data.cardId;
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.csId = params.csId;
    });
    setTimeout(() => {
      this.getPartyDetail();
    }, 200);
  }

  getPartyDetail() {
    this.spinner.show();
    this.service.partyGet(this.csId).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.profileData = res.body;
      }
    });
  }

  createReplaceCardForm() {
    this.replaceCardForm = this.fb.group({
      address1: [null, [Validators.required, CustomValidators.alphanumeric]],
      address2: [null, CustomValidators.alphanumeric],
      address3: [null, CustomValidators.alphanumeric],
      city: [null, [Validators.required, CustomValidators.alphanumeric]],
      state: [null, [Validators.required, CustomValidators.alphanumeric]],
      zip: [null, [Validators.required, CustomValidators.customZip]],
    });
  }

  addressToggleChange() {
    const profile = this.isSameAsHomeAddress ? this.profileData : ({} as any);
    this.replaceCardForm.controls.address1.setValue(profile.address1);
    this.replaceCardForm.controls.address2.setValue(profile.address2);
    this.replaceCardForm.controls.address3.setValue(profile.address3);
    this.replaceCardForm.controls.city.setValue(profile.city);
    this.replaceCardForm.controls.state.setValue(profile.state);
    this.replaceCardForm.controls.zip.setValue(profile.zip);
  }

  onReplaceCardFormSubmit() {
    this.submitted = true;
    if (this.replaceCardForm.invalid) {
      return;
    } else {
      const finalPayload = this.createFinalPaylaod();
      this.replaceCard(finalPayload);
    }
  }
  replaceCard(payload) {
    this.spinner.show();
    let reqObj = {
      id: Number(this.cardId),
      ...payload,
    };
    this.service.replaceCard(reqObj).subscribe({
      next: (resp: HttpResponse<any>) => {
        this.spinner.hide();
        if (resp.body) {
          this.customeSnakbar.openCustomSnakbar(
            "Card replaced succesfully!",
            "",
            "success"
          );
          this.dialogRef.close(resp.body);
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.customeSnakbar.openCustomSnakbar(
          error?.error?.["errorMessage"],
          "",
          "error"
        );
      },
    });
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  createFinalPaylaod() {
    const postData: any = {};

    postData.status = "A";
    postData.shippingAddress = {};
    postData.shippingAddress.address1 = this.replaceCardForm.value.address1
      ? this.replaceCardForm.value.address1
      : "";
    postData.shippingAddress.address2 = this.replaceCardForm.value.address2
      ? this.replaceCardForm.value.address2
      : "";
    postData.shippingAddress.address3 = this.replaceCardForm.value.address3
      ? this.replaceCardForm.value.address3
      : "";
    postData.shippingAddress.city = this.replaceCardForm.value.city
      ? this.replaceCardForm.value.city
      : "";
    postData.shippingAddress.zip = this.replaceCardForm.value.zip
      ? this.replaceCardForm.value.zip
      : "";
    postData.shippingAddress.state = this.replaceCardForm.value.state
      ? this.replaceCardForm.value.state
      : "";

    return postData;
  }
}
