/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-security-dialog',
  templateUrl: './security-dialog.component.html',
  styleUrls: ['./security-dialog.component.scss']
})
export class SecurityDialogComponent {

  submitted = false;
  validateSecurityForm: UntypedFormGroup;
  question = '';
  username: string;
  hide = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<SecurityDialogComponent>,
    private service: RestServicesService,
    private fb: UntypedFormBuilder,
    public customeSnakbar:CustomSnakbarComponent,
    private spinner: NgxSpinnerService
  ) {
    if (this.data) {
      this.question = this.data.question;
      this.username = this.data.username;
    }

    this.validateSecurityForm = this.fb.group({
      answer: [null, [Validators.required, CustomValidators.alphanumeric]],
    });
  }



  get f() { return this.validateSecurityForm.controls; }

  validateSecurityQuestion() {
    const postData = {
      username: this.username.trim(),
      securityQuestion: this.question,
      securityAnswer: this.f.answer.value
    };

    this.spinner.show();
    this.service.validateUser(postData).subscribe(securityResp => {

      this.dialogRef.close(true);
    });
  }

  onFormSubmit() {
    this.submitted = true;

    if (this.validateSecurityForm.invalid) {
      return;
    }
    this.validateSecurityQuestion();
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

}
