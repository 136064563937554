/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { CoreBaseUrlKeyModel, CoreFeatureUrlKeyModel } from '../shared/model/core';
import { environment } from '../../environments/environment';
import * as CryptoJs from 'crypto-js';

@Injectable()
export class AuthService {
  applicationMode = "sessionStorage";
  private loggedIn = new BehaviorSubject<boolean>(this.tokenAvailable());

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataservice: DataService
  ) {}

  public encrypt(txt: string): string {
    return CryptoJs.AES.encrypt(txt, environment.cyptokey).toString();
  }

  public decrypt(txtToDecrypt: string) {
    return CryptoJs.AES.decrypt(txtToDecrypt, environment.cyptokey).toString(
      CryptoJs.enc.Utf8
    );
  }

  private tokenAvailable(): boolean {
    return !!this.getAppMode().getItem("accessToken");
  }

  getAppMode() {
    return window[this.applicationMode];
  }

  getTimeoutConfig() {
    return this.http.get("../../assets/timeout.json");
  }

  setProfileRequired(val) {
    this.getAppMode().setItem("profileRequired", val);
  }
  getProfileRequired() {
    return this.getAppMode().getItem("profileRequired");
  }
  deleteProfileRequired() {
    this.getAppMode().removeItem("profileRequired");
  }
  setExernalAuth(value) {
    this.getAppMode().setItem("externalroute", value);
  }
  public getExernalAuth() {
    return (
      this.getAppMode().getItem("externalroute") !== null &&
      this.getAppMode().getItem("externalroute") === "true"
    );
  }

  login(): void {
    this.loggedIn.next(true);
  }

  logout(): void {
    this.getAppMode().clear();
    this.loggedIn.next(false);
    localStorage.removeItem("userId");
  }

  getAccessToken() {
    return this.getAppMode().getItem("accessToken");
  }

  setToken(data: any) {
    this.getAppMode().setItem("accessToken", data.body.apiToken);
    this.getAppMode().setItem("tokenValidity", data.body.tokenValidity);
  }

  setData(userData: any, partyData: any): void {
    const user = {
      userDetails: userData,
    };
    const party = {
      partyDetails: partyData,
    };

    this.getAppMode().setItem(
      "partyType",
      this.encrypt(party.partyDetails.partyType)
    );
    this.getAppMode().setItem("bankId", user.userDetails.bankId);
  }

  setSelectedBank(bank) {
    this.getAppMode().setItem("selectedBankId", bank.id);
  }

  setEndpoints(urls: CoreFeatureUrlKeyModel) {
    for (const urlsKey in urls) {
      if (urls.hasOwnProperty(urlsKey)) {
        this.getAppMode().setItem(urlsKey, urls[urlsKey]);
      }
    }
  }

  getEndpoints() {
    return {
      user_url: this.getAppMode().getItem("user_api_url"),
      doc_url: this.getAppMode().getItem("doc_api_url"),
      statement_url: this.getAppMode().getItem("statement_api_url"),
      account_url: this.getAppMode().getItem("account_api_url"),
      bank_url: this.getAppMode().getItem("bank_api_url"),
      report_url: this.getAppMode().getItem("report_api_url"),
      solr_url: this.getAppMode().getItem("solr_api_url"),
      gl_url: this.getAppMode().getItem("gl_api_url"),
    };
  }

  getConfig(): Observable<CoreBaseUrlKeyModel> {
    return this.http.get<CoreBaseUrlKeyModel>("../../assets/api_config.json");
  }
}
