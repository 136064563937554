/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AddTransactionLimitDialogComponent } from '../../rules/add-transaction-limit-dialog/add-transaction-limit-dialog.component';
// tslint:disable-next-line: max-line-length
import { UpdateTransactionLimitDialogComponent } from '../../rules/update-transaction-limit-dialog/update-transaction-limit-dialog.component';
import { RemoveControlComponent } from 'src/app/shared/shared-component/remove-control/remove-control.component';
import { CommonUtility } from 'src/app/utility/common.utility';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-daily-transaction',
  templateUrl: './daily-transaction.component.html',
  styleUrls: ['./daily-transaction.component.scss']
})
export class DailyTransactionComponent implements OnInit, OnDestroy {
  dataSource = [] as any;
  accountId: number;

  merchantType = {} as any;
  transactionLimit = {} as any;
  control = 'txnlimit';
  obsMerchantTypedata = null;

  paginationConfig = {
    CurrentPage: 1,
    ItemsPerPage: 5,
    TotalItems: 0,
    Offset: 0
  };

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private customeSnakbar:CustomSnakbarComponent,
    private commonApi: CommonUtility
  ) { }

  ngOnInit() {
    this.GetDailyData();

    if (this.obsMerchantTypedata === null) {
      this.obsMerchantTypedata = this.dataservice.obsMerchantTypedata.subscribe((resp: any) => {
        if (resp.length > 0) {
          resp.forEach((v: any, k: any) => {
            this.merchantType[v.code] = v.description;
          });
        } else {
          this.commonApi.GetMerchantTypeAsyc();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.obsMerchantTypedata) {
      this.obsMerchantTypedata.unsubscribe();
    }
  }

  pageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    this.GetDailyData();
  }

  GetDailyData() {
    this.route.queryParams.subscribe(params => {
      this.accountId = params.accountId;
    });

    this.spinner.show();

    this.service.getDailyTransactionLimits(this.accountId, 'AP').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.paginationConfig.TotalItems = res.body.data.length;
        this.dataSource = res.body.data;
      }
    });
  }

  addTransactionLimitDialog(): void {
    this.spinner.show();

    const dialogRef = this.dialog.open(AddTransactionLimitDialogComponent, {
      width: '50%',
      data: this.dataSource
    });
    dialogRef.afterClosed().subscribe(() => {
      this.GetDailyData();
    });
  }
  updateTransactionLimitDialog(data: any, index: number): void {
    const dialogRef = this.dialog.open(UpdateTransactionLimitDialogComponent, {
      width: '50%',
      data: { source: Object.assign({}, data), index }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.GetDailyData();
    });
  }
  removeTransactionLimitDialog(data: any, index: number): void {
    this.transactionLimit = {
      entityType: 'AP',
      entityId: Number(this.accountId),
      limitType: data.limitType,
      mccType: data.mccType
    };

    const message = 'Are you sure you want to remove this Transaction Limit Rule ?';
    const okFlag = 'Remove';

    const dialogRef = this.dialog.open(RemoveControlComponent, {
      data: { message, index, okFlag }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.spinner.show();
        this.service.deleteControl(this.control, this.transactionLimit).subscribe((resp: any) => {
          this.spinner.hide();
          if (resp.status === 200) {
            this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
            this.GetDailyData();
          }
        });
      }
    });
  }
}
